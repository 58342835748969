import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allRoutes } from "../../Routes/AllRoutes";
import PageLayout from "../PageLayout/PageLayout";
import {  getLeaderBoard } from "../../Services/installersService";
import { useSelector } from "../../Redux/reduxHooks";
import { UserState, selectUser } from "../../Redux/Slices/userSlice";
import { TableBlock } from "../Dashboard/Dashboard";
import { roles } from "../../Utils/tokenKeyValue";
import AvatarCell from "../Common/Table/AvatarCell";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

export const tableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
	{
		text: "Name",
		key: "name",
		customComponent: (props: { text: string; fullObject: any }) => (
			<AvatarCell logo={props.fullObject.ImageUrl} name={props.text} />
		),
	},
	{ text: "Leads", key: "leads", align: "right", sortable: true },
	{ text: "Accepted Proposals", key: "acceptedProposals", sortable: true, align: "right" },
	{ text: "Signed Agreements", key: "signedAgreements", sortable: true, align: "right" },
	{ text: "Sold Revenue", key: "soldRevenue", sortable: true, align: "right" },
	{ text: "Installs", key: "installs", sortable: true, align: "right" },
	
];

const OfficeManagers = () => {
	const user : UserState = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);


	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<{
		admins?: Array<any>;
		managers?: Array<any>;
		officeManagers?: Array<any>;
		customers?: Array<any>;
		representatives?: Array<any>;
	}>({
		admins: [],
		officeManagers: [],
		managers: [],
		customers: [],
		representatives: [],
	});

	useEffect(() => {
		getAllUsers();
	}, [user]);

	const getAllUsers = async () => {
		if (!user.id) return;

		setLoading(true);
		try {
			const { data } = await getLeaderBoard(roles.OFFICE_MANAGER);

			setData({ officeManagers: data });
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<PageLayout loading={loading} hideBackButton>
			<TableBlock
				heading={textSnippets['office_managers_heading_installer_dashboard_admin_page'] || "Office Managers"}
				subHeading={textSnippets['office_managers_subheading_installer_dashboard_admin_page'] ||`These are all the office managers in this company:`}
				tableData={data.officeManagers}
				addButtonText={textSnippets['add_office_manager_button_installer_dashboard_admin_page'] || "Add Office Manager"}
				addButtonPath={allRoutes.ADD_OFFICE_MANAGER}
				addButtonState={null}
				detailsPagePath={allRoutes.VIEW_OFFICE_MANAGER}
				tableHeaders={tableHeaders}
				emptyStateMessage={textSnippets['no_office_managers_message_installer_dashboard_admin_page'] ||"There are no office managers present. Please add an office manager"}
			/>
		</PageLayout>
	);
};

export default OfficeManagers;
