import { FormEvent, useState } from "react";
import { UserState } from "../../Redux/Slices/userSlice";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { Box, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { PlaceType } from "../Common/GoogleMapsTextField";
import PageLayout from "../PageLayout/PageLayout";
import { allRoutes } from "../../Routes/AllRoutes";
import { validatePassword } from "../../Utils/utils";
import CustomDialog from "../Common/CustomDialog";
import { IconSquareBox } from "../Login/loginStyles";
import { EmailOutlined } from "@mui/icons-material";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { registerNewTechnician } from "../../Services/technicianService";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { useSelector } from "../../Redux/reduxHooks";

interface AccountSettingsData extends UserState {
	confirmPassword?: string;
	addressObject?: PlaceType | null;
	currentStep?: string;
	status?: string;
}

const defaultData = {
	name: "",
	email: "",
};

const AddTechnician = () => {
	const navigate = useNavigate();
	const textSnippets = useSelector(selectAllTextSnippets);

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: name === "password" ? validatePassword(value) : "" }));
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.name = data.name ? "" : "Name cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const formData = new FormData();
			// formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("email", data.email ?? "");
			// formData.append("phone_no", data.phone ?? "");

			await registerNewTechnician(formData);

			setOpen(true);
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = "A user with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(-1);
	};

	return (
		<PageLayout loading={loading}>
			<Typography variant="h5">{textSnippets['textSnippets'] || 'Register new Installation Crew'}</Typography>
			<Typography fontSize={15} mt={10}>
				{textSnippets['register_technician_details_instruction'] || 'After Registration it will send a email to installation crew to add his information.'}
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdate}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="center"
				>
					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Name
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.name}
						name="name"
						placeholder="Name"
						error={errors.name}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Email address
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.email}
						error={errors.email}
						name="email"
						type="email"
						placeholder="@example"
					/>

					<Box />
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">Register Installation Crew</CustomButton>
					</Box>
					<VerifyEmailSentDialog open={open} onClose={() => navigate(allRoutes.TECHNICIANS)} />
				</Box>
			</form>
		</PageLayout>
	);
};

export default AddTechnician;

interface VerifyEmailSentDialogProps {
	open: boolean;
	onClose?: () => void;
}

const VerifyEmailSentDialog = ({ open, onClose }: VerifyEmailSentDialogProps) => {
	const colors = useSelector(selectColors);
	return (
		<CustomDialog open={open} onClose={onClose}>
			<IconSquareBox>
				<EmailOutlined />
			</IconSquareBox>

			<Typography variant="h2" my={16} textAlign="center">
				Email Sent
			</Typography>
			<Typography fontSize={16} textAlign="center" mb={32} color={colors.textMid}>
				We've sent installation crew an email to complete his registration.
				<br />
				Tip: Please check your spam folder in case the email was mistakenly filtered.
			</Typography>

			<CustomButton fullWidth onClick={onClose} sx={{ mb: 10 }}>
				Close
			</CustomButton>
		</CustomDialog>
	);
};
