import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Call,
    CallControls,
    CallingState,
    SpeakerLayout,
    StreamCall,
    StreamTheme,
    StreamVideo,
    StreamVideoClient,
    useCallStateHooks,
} from '@stream-io/video-react-sdk';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import '../../App.css';
import { allRoutes } from '../../Routes/AllRoutes';
import Loader from '../Common/Loader';
import { toast } from 'react-toastify';
import { StreamChat, Channel as StreamChannel, DefaultGenerics } from 'stream-chat';
import { getAuthHeader } from '../../Services/userService';
import { Chat, Channel, MessageList, MessageInput, Window } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import { Box, IconButton, Tooltip } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { useSelector } from '../../Redux/reduxHooks';
import { RemoveRedEye } from '@mui/icons-material';
// import { redirectToUserDashboard } from '../Customers/CustomerDetails';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { selectColors } from "../../Redux/Slices/generalSlice";


// Declare UILayoutProps interface here
interface UILayoutProps {
    isAdmin: boolean;
}
function CsrVideoChat(): JSX.Element | null {
    const colors = useSelector(selectColors);
    const fetchRepToken = async (): Promise<string> => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/get-rep-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                ...getAuthHeader(),
            },
        });

        if (!response.ok) {
            throw new Error('Failed to fetch representative token');
        }

        const data = await response.json();
        return data.data;
    };

    const { state }: any = useLocation();
    const navigate = useNavigate();
    const apiKey = state?.apiKey;
    const token = state?.token;
    const userId = state?.userId;
    const chatId = state?.chatId;
    const repName = state?.repName;

    const isAdmin = state?.isAdmin;

    const user = {
        id: userId,
        name: repName || 'Representative',
        image: `https://getstream.io/random_svg/?id=representative&name=${repName}`,
    };

    const client = useRef(StreamVideoClient.getOrCreateInstance({ apiKey, user, token }));
    const [call, setCall] = useState<Call | null>(null);
    const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | null>(null);
    const [channel, setChannel] = useState<StreamChannel<DefaultGenerics> | undefined>(undefined);
    const [showChat, setShowChat] = useState<boolean>(false);

    useEffect(() => {
        const initializeChat = async () => {
            if (!apiKey || !token || !userId) {
                toast.error('Missing required parameters to initialize chat.');
                return;
            }

            try {
                const repToken = await fetchRepToken();
                const chatClient = StreamChat.getInstance(apiKey);

                if (userId) {
                    await chatClient.connectUser(
                        {
                            id: String(userId),
                            name: repName || "Unknown User",
                        },
                        repToken
                    );

                    setChatClient(chatClient);

                    const chatChannel = chatClient.channel('messaging', chatId);
                    await chatChannel.watch();
                    setChannel(chatChannel);
                } else {
                    throw new Error('User ID is undefined');
                }
            } catch (err: unknown) {
                if (err instanceof Error) {
                    toast.error(`Chat Initialization Error: ${err.message}`);
                }
            }
        };

        initializeChat();

        return () => {
            if (chatClient) {
                chatClient.disconnectUser();
                setChatClient(null);
            }
        };
    }, [apiKey, token, userId, chatId, repName]);

    useEffect(() => {
        const myCall = client.current.call('default', chatId);
        myCall.join({ create: true })
            .then(() => {
                setCall(myCall);
            })
            .catch((err: any) => {
                toast.error(err);
            });

        myCall.on('call.ended', () => {
            console.log("asdasdsadasd")
            navigate(allRoutes.CHAT)

        });

        return () => {
            if (myCall) {
                myCall.leave().catch((err: any) => {
                    toast.error(err);
                });
                setCall(null);
            }
        };
    }, [chatId, navigate]);

    if (!call) return null;
    const cb: any = channel?.data?.created_by
    const customerId = cb?.id || ''
    const isAnonUser = customerId.length < 24
    return (
        <StreamVideo client={client.current}>
            <StreamCall call={call}>
                <StreamTheme>
                    <UILayout isAdmin={isAdmin} />
                    {chatClient && channel && showChat ? (
                        <Box className="floating-chat">
                            <Chat client={chatClient} theme='messaging light'>
                                <Channel channel={channel}>
                                    <Window >
                                        <Box sx={{
                                            display: "flex", alignItems: "center", position: "relative", height: "30px"
                                        }}>

                                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", position: "absolute", right: "20px", zIndex: "2", gap: "8px", "& button": { padding: "4px 8px", fontSize: "12px", width: 30, height: 30, minWidth: 0 } }}>

                                                <Tooltip arrow title={isAnonUser ? "Cannot view customers who have not signed up." : "View customer"}>
                                                    <Box>

                                                        <IconButton disabled={isAnonUser} onClick={() => {
                                                            navigate(allRoutes.VIEW_CUSTOMER.replace(":id", String(customerId || '')));
                                                        }}>
                                                            <RemoveRedEye />
                                                        </IconButton>
                                                    </Box>
                                                </Tooltip>
                                                <Tooltip arrow title={isAnonUser ? "Cannot view customers proposal who have not signed up." : "View Customer Proposal"}>
                                                    <Box>

                                                        <IconButton disabled={isAnonUser} onClick={() => {
                                                            // redirectToUserDashboard(customerId)
                                                        }}><OpenInNewIcon /> </IconButton>
                                                    </Box>
                                                </Tooltip>

                                            </Box>

                                        </Box>
                                        <MessageList />
                                        <MessageInput />
                                    </Window>
                                </Channel>
                            </Chat>
                        </Box>
                    ) : null}
                    <Box className="chat-bubble" sx={{ backgroundColor: colors.primary }} onClick={() => setShowChat(!showChat)}>
                        {showChat ? <ChatIcon /> : <ChatIcon />}
                    </Box>
                </StreamTheme>
            </StreamCall>
        </StreamVideo >
    );
}

function UILayout({ isAdmin }: UILayoutProps): JSX.Element {
    const { useCallCallingState } = useCallStateHooks();
    const callingState = useCallCallingState();
    const navigate = useNavigate();

    useEffect(() => {
        if (callingState === CallingState.LEFT) {

            navigate(allRoutes.CHAT)

        }

    }, [callingState, navigate]);

    if (callingState !== CallingState.JOINED) {
        return <Loader />;
    }


    return (
        <StreamTheme>
            <SpeakerLayout participantsBarPosition="bottom" />
            <CallControls />
        </StreamTheme>
    );
}

export default CsrVideoChat;


