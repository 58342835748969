import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import PageLayout from "../PageLayout/PageLayout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getCustomerDetails } from "../../Services/dashboardService";
import { toast } from "react-toastify";
import { allRoutes } from "../../Routes/AllRoutes";
import { getDocumentsOfCustomer } from "../../Services/installersService";
import { DownloadOutlined } from "@mui/icons-material";
import TableBlock from "../Common/Table/TableBlock";

const CustomerDocuments = () => {
	const { id } = useParams();

	const [loading, setLoading] = useState(false);
	const [customer, setCustomer] = useState<any>(null);
	const [documents, setDocuments] = useState<Array<any>>([]);

	useEffect(() => {
		getData();
	}, []);

	const getData = async (getOnlyLogs?: boolean) => {
		setLoading(!getOnlyLogs);
		try {
			const { data: userData } = await getCustomerDetails((id || "")?.toString());
			setCustomer(userData);

			if (userData?._id) {
				const { data: logs } = await getDocumentsOfCustomer((userData?._id || "")?.toString());
				setDocuments(logs);
			}
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleDownload = (filePath: string, fileName: string) => {
		const link = document.createElement("a");

		link.download = fileName;
		link.href = filePath;
		link.click();
	};

	const tableHeaders = [
		{
			text: "Document",
			key: "name",
			customComponent: (props: { text: string }) => <Typography fontWeight={600}>{props.text}</Typography>,
		},
		{
			text: "Download",
			key: "file",
			align: "center",
			customComponent: (props: { fullObject: any }) => {
				return (
					<Tooltip title={"Download Document"} placement="top" arrow>
						<span>
							<IconButton
								color="primary"
								onClick={() => handleDownload(props.fullObject?.file, props.fullObject?.name)}
							>
								<DownloadOutlined />
							</IconButton>
						</span>
					</Tooltip>
				);
			},
		},
	];

	return (
		<PageLayout loading={loading} backButtonPath={allRoutes.VIEW_CUSTOMER.replace(":id", id || "")}>
			<Box mt={24} />
			<TableBlock
				heading={"Documents"}
				subHeading={`These are all the documents of ${customer?.name}:`}
				tableData={documents}
				tableHeaders={tableHeaders}
				emptyStateMessage="There are no documents present."
				rowsPerPage={20}
			/>

			{/* <Typography variant="h4" mb={8}>
				Documents
			</Typography>
			<Typography fontSize={16} mb={32}>{`These are all the documents of ${customer?.name}:`}</Typography>

			{documents?.length ? (
				documents?.map((doc) => (
					<Box key={doc.name} mb={8} display="flex" alignItems="center" gap={8}>
						<Typography variant="h5" minWidth={150}>
							{doc.name}
						</Typography>
						<Tooltip title="Download document" placement="top">
							<IconButton color="primary" onClick={() => handleDownload(doc.file, doc.name)}>
								<DownloadOutlined />
							</IconButton>
						</Tooltip>
					</Box>
				))
			) : (
				<PlaceholderForEmptyTable message={"There are no documents present."} />
			)} */}
		</PageLayout>
	);
};

export default CustomerDocuments;
