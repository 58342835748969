import { FormEvent, useEffect, useState } from "react";
import CustomButton from "../Common/CustomButton";
import { Box, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import ColorPicker from "../Common/ColorPicker";
import { addColors, getColors, updateColors } from "../../Services/colorService";
import { allRoutes } from "../../Routes/AllRoutes";
import { useDispatch } from "../../Redux/reduxHooks";
// import { fetchColors } from "../../Redux/Slices/generalSlice";
import ImageUploader from "../Common/ImageUploader";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { fetchColors } from "../../Redux/Slices/generalSlice";

interface ColorsInterface {
	primary: string;
	text: string;
	textSecondary: string;
}

const defaultData = {
	primary: "#FFA800",
	text: "#1C1D34",
	textSecondary: "#475467",
};

const ColorTheme = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [themeColors, setThemeColors] = useState<ColorsInterface>(defaultData);
	const [applicationLogo, setApplicationLogo] = useState<any>("");
	const [siteTitle, setSiteTitle] = useState<string>("");
	const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);

	useEffect(() => {
		getDetails();
	}, []);

	const getDetails = async () => {
		setLoading(true);
		try {
			const { data } = await getColors();
			// const { data: faviconData } = await getFaviconText();

			console.log("COL", data);

			if (data) {
				setIsUpdateMode(true);
				setThemeColors({
					primary: data?.primary || defaultData.primary,
					text: data?.text || defaultData.text,
					textSecondary: data?.textSecondary || defaultData.textSecondary,
				});
				setSiteTitle(data.title);
				setApplicationLogo(data.favicon);
			} else {
				setIsUpdateMode(false);
			}



		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleUpdate = async () => {
		if (!siteTitle || !applicationLogo) return setError(true)
		else setError(false)
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("favicon", applicationLogo ?? "");
			formData.append("title", siteTitle ?? "");
			formData.append("primary", themeColors.primary ?? "");
			formData.append("text", themeColors.text ?? "");

			// updating colors in redux
			// Dispatching the fetchColors action and logging the promise result
			const { data: output } = await updateColors(formData);
			const result = dispatch(fetchColors());

			toast.success("Theme updated successfully!");
			navigate(allRoutes.CONFIGURATIONS);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};
	const handleAdd = async () => {
		if (!siteTitle || !applicationLogo) return setError(true)
		else setError(false)
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("favicon", applicationLogo ?? "");
			formData.append("title", siteTitle ?? "");
			formData.append("primary", themeColors.primary ?? "");
			formData.append("text", themeColors.text ?? "");
			const { data: output } = await addColors(formData);

			// updating colors in redux
			dispatch(fetchColors());

			toast.success("Theme added successfully!");
			navigate(allRoutes.CONFIGURATIONS);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (isUpdateMode) {
			await handleUpdate();
		} else {
			await handleAdd();
		}
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSiteTitle(value)
		// setData((state) => ({ ...state, [name]: value }));
		// setErrors((state) => ({ ...state, [name]: name === "password" ? validatePassword(value) : "" }));
	};
	const handleCancel = () => {
		navigate(allRoutes.CONFIGURATIONS);
	};

	const handleSelectImage = (image: any) => {
		setApplicationLogo(image);
	};

	return (
		<PageLayout loading={loading}>
			<Typography variant="h5">Theme</Typography>
			<Typography fontSize={15} mt={10}>
				These are all the colors, favicon & title used in our applications
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleSubmit}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="center"
				>
					{/* <Box alignSelf="flex-start">
						<Typography variant="h5">Company Logo</Typography>
						<Typography fontSize={15} mt={10}>
							This will be displayed on installer company's dashboard
						</Typography>
					</Box>
					<Box>
						<ImageUploader isLogo onUpdate={handleSelectImage} imageFile={profilePicture} />
					</Box> */}

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Primary
					</Typography>
					<ColorPicker
						color={themeColors.primary}
						onChange={(color: string) => setThemeColors({ ...themeColors, primary: color })}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Typography
					</Typography>
					<ColorPicker
						color={themeColors.text}
						onChange={(color: string) => setThemeColors({ ...themeColors, text: color })}
					/>
					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Favicon <Asterisk />
					</Typography>
					<Box>
						<ImageUploader onUpdate={handleSelectImage} imageFile={applicationLogo} isLogo acceptIcon />
						{error && !applicationLogo ? <Typography mt={3} ml={14} color="error.main" >Favicon can not be empty</Typography> : <></>}
					</Box>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Site Title <Asterisk />
					</Typography>
					<CustomTextField error={error && !siteTitle ? "Site Title can not be empty" : ""} onChange={handleOnChange} name="title" value={siteTitle} placeholder="Site Title" />
					{/* value={data.name}  */}

					{/* <Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Typography Secondary
					</Typography>
					<ColorPicker
						color={themeColors.textSecondary}
						onChange={(color: string) => setThemeColors({ ...themeColors, textSecondary: color })}
					/> */}

					<Box />
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">{isUpdateMode ? "Save Changes" : "Add Theme"}</CustomButton>
					</Box>
				</Box>
			</form>
		</PageLayout>
	);
};

export default ColorTheme;
