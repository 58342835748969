import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allRoutes } from "../../Routes/AllRoutes";
import PageLayout from "../PageLayout/PageLayout";
import { getAllInstallerAdmins } from "../../Services/installersService";
import { useSelector } from "../../Redux/reduxHooks";
import { selectUser } from "../../Redux/Slices/userSlice";
import { TableBlock } from "../Dashboard/Dashboard";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { Typography } from "@mui/material";

export const tableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
	{ text: "Name", key: "name" },
	{ text: "Email address", key: "email" },
	{
		text: "Phone Number",
		key: "phone_no",
		customComponent: (props: { text: string; fullObject: any }) => (
			<Typography component="span" width="max-content">
				{props.text || "-"}
			</Typography>
		),
	},
	{
		text: "Email Status",
		key: "isEmailVerified",
		customComponent: (props: any) => (props.fullObject.isEmailVerified ? "Verified" : "Not Verified"),
	},
];

const InstallerAdmins = () => {
	const user = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<{
		admins?: Array<any>;
		managers?: Array<any>;
		officeManagers?: Array<any>;
		customers?: Array<any>;
		representatives?: Array<any>;
	}>({
		admins: [],
		officeManagers: [],
		managers: [],
		customers: [],
		representatives: [],
	});

	useEffect(() => {
		getAllUsers();
	}, [user]);

	const getAllUsers = async () => {
		if (!user.id) return;

		setLoading(true);
		try {
			const { data: allAdmins } = await getAllInstallerAdmins();

			setData({ admins: allAdmins });
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<PageLayout loading={loading} hideBackButton>
			<TableBlock
				heading={textSnippets["installer_admins_heading_installer_dashboard_admin_page"] || "Installer Admins"}
				subHeading={
					textSnippets["installer_admins_subheading_installer_dashboard_admin_page"] ||
					"These are all the installer admins in this company:"
				}
				tableData={data.admins}
				addButtonText={
					textSnippets["add_installer_admin_button_installer_dashboard_admin_page"] || "Add Installer Admin"
				}
				addButtonPath={allRoutes.ADD_INSTALLER_ADMIN}
				detailsPagePath={allRoutes.VIEW_INSTALLER_ADMIN}
				tableHeaders={tableHeaders}
				emptyStateMessage={
					textSnippets["no_installer_admins_message_installer_dashboard_admin_page"] ||
					"There are no installer admins present. Please add an installer admin"
				}
			/>
		</PageLayout>
	);
};

export default InstallerAdmins;
