import React, { useState, MouseEvent, KeyboardEvent } from "react";
import { CheckRounded as Check, ContentCopyRounded as CopyIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface CopyableTextProps {
	children?: React.ReactNode;
	copyableText?: string;
	onCopy?: (text?: string) => void;
}

const CopyableText: React.FC<CopyableTextProps> = ({ children, copyableText = "", onCopy }: CopyableTextProps) => {
	const [copied, setCopied] = useState(false);

	const handleCopy = (event: MouseEvent<HTMLDivElement> | undefined) => {
		event?.preventDefault();

		if (copied) return;

		if (navigator.clipboard) navigator.clipboard.writeText(copyableText);
		else copyToClipboard(copyableText);
		setCopied(true);
		onCopy?.(copyableText);
		setTimeout(() => setCopied(false), 2000);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter" || event.key === "Space") {
			event.preventDefault();
			handleCopy(undefined);
		}
	};

	return (
		<div
			style={{
				display: "inline-flex",
				justifyContent: "stretch",
				alignItems: "center",
				cursor: "pointer",
				gap: "5px",
			}}
			onClick={handleCopy}
			onKeyDown={handleKeyDown}
			role="button"
			tabIndex={0}
		>
			<IconButton>
				{copied ? (
					<Check sx={{ width: "14px", height: "14px", color: "success.dark" }} />
				) : (
					<CopyIcon sx={{ width: "14px", height: "14px" }} />
				)}
			</IconButton>
			{!!children && <div style={{ width: "100%" }}>{children}</div>}
		</div>
	);
};

export default CopyableText;

const copyToClipboard = async (text: string): Promise<boolean> => {
	let textarea: HTMLTextAreaElement | undefined;
	let result = false;

	try {
		textarea = document.createElement("textarea");
		if (!textarea) throw new Error("Failed to create textarea element");

		textarea.setAttribute("readonly", "true");
		textarea.style.position = "fixed";
		textarea.value = text;

		document.body.appendChild(textarea);

		textarea.focus();
		textarea.select();

		const range = document.createRange();
		range.selectNodeContents(textarea);

		const sel = window.getSelection();
		sel?.removeAllRanges();
		sel?.addRange(range);

		textarea.setSelectionRange(0, textarea.value.length);
		result = document.execCommand("copy");
	} catch (err) {
		console.error(err);
		result = false;
	} finally {
		textarea && document.body.removeChild(textarea);
	}

	if (!result) {
		alert("This action is not supported in this browser");
		return false;
	}
	return true;
};
