export const getTokensOfGoogleCalendar = async (code: string) => {
	const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID || "";
	const REDIRECT_URI = `${window.location.origin}/account-settings?google-calendar=true`;
	const CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_SECRET || "";
	const url = "https://oauth2.googleapis.com/token";

	const requestBody = {
		code,
		client_id: CLIENT_ID,
		client_secret: CLIENT_SECRET,
		redirect_uri: REDIRECT_URI,
		grant_type: "authorization_code",
		access_type: "offline",

		// prompt: "consent",
		prompt: "select_account%20consent",
	};

	try {
		const response = await fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: new URLSearchParams(requestBody).toString(),
		});

		if (response.ok) {
			const tokens = await response.json();
			return tokens;
		} else {
			console.error("Error retrieving tokens:", response.statusText);
			return "";
		}
	} catch (error) {
		console.error("Error retrieving tokens:", error);
		return "";
	}
};
