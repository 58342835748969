import { FormEvent, useEffect, useState } from "react";
import { UserState, selectUser } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "../../Redux/reduxHooks";
import CustomButton from "../Common/CustomButton";
import CustomTextField from "../Common/CustomTextField";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import { getProfile, updateProfile } from "../../Services/profileService";
import ImageUploader from "../Common/ImageUploader";
import * as EmailValidator from "email-validator";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";
import OtpVerifyDialog from "./OtpVerifyDialog";
import { validatePassword } from "../../Utils/utils";
import PageLayout from "../PageLayout/PageLayout";
import MuiPhoneNumber from "material-ui-phone-number";
import {
	// getSlotsOfGoogle,
	storeGoogleCalendarTokens,
	outlookCalendarCallbackApi,
} from "../../Services/calendarService";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { borderRadius } from "../../Utils/spacings";
import { isRepresentativeLoggedIn, isTechnicianLoggedIn } from "../../Services/userService";
import { getReferralLink } from "../../Services/installersService";
import CopyableText from "../Common/CopyableText";
import { GoogleIcon, MicrosoftIcon } from "../../Utils/icons";
import { getTokensOfGoogleCalendar } from "./callbackFunctions";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

interface AccountSettingsData extends UserState {
	changePassword?: string;
	addressObject?: PlaceType | null;
}

const defaultData = {
	name: "",
	email: "",
	addressObject: null,
	bill: "",
	phone: "",
	password: "",
	changePassword: "",
};

const AccountSettings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);
	const colors = useSelector(selectColors);
	const [searchParams, setSearchParams] = useSearchParams();

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [otpDialog, setOtpDialog] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");
	const [updatingEmail, setUpdatingEmail] = useState<any>("");
	const [referralLink, setReferralLink] = useState<string>("");

	useEffect(() => {
		// getSlotsOfGoogle();

		if (user) {
			const userData = {
				name: user.name,
				email: user.email,
				addressObject: user.addressObject
					? user.addressObject
					: user.address
						? {
							description: user.address,
							structured_formatting: {
								main_text: user.address,
								secondary_text: user.address,
							},
						}
						: null,
				bill: user.bill,
				phone: user.phone,
				refreshToken: user.refreshToken,
				outlookRefreshToken: user.outlookRefreshToken,
			};
			setData(userData);
			setProfilePicture(user.picture);
		}
	}, [user]);

	useEffect(() => {
		completeCalendarSync();
	}, []);

	console.log("NEW UPDATE: NEW PROMPT IN AUTH URL");

	const completeCalendarSync = async () => {
		setLoading(true);
		const googleCode = searchParams.get("code");
		const isGoogleCalendar = searchParams.get("google-calendar") === "true";

		if (isGoogleCalendar) {
			if (googleCode) {
				try {
					const tokens = await getTokensOfGoogleCalendar(googleCode);
					console.log("TOKENS: ", tokens);

					if (tokens) {
						searchParams.delete("code");
						searchParams.delete("scope");
						searchParams.delete("google-calendar");
						setSearchParams(searchParams);

						const res = await storeGoogleCalendarTokens(tokens);
						if (res) {
							await dispatch(getProfile()); // getting the updated profile from db
						}
					}
				} catch (error) {
					console.error("Error found in TOKENS: ", error);
					toast.error("Failed to sync google calendar.");
				}
			}
		} else {
			const codeOutlook = searchParams.get("code");
			if (codeOutlook) {
				try {
					const res = await outlookCalendarCallbackApi(codeOutlook);
					if (res) {
						await dispatch(getProfile()); // getting the updated profile from db
						searchParams.delete("code");
						searchParams.delete("scope");
						searchParams.delete("state");
						setSearchParams(searchParams);
					}
				} catch (error) {
					console.error(error);
					toast.error("Failed to sync outlook calendar.");
				}
			}
		}
		setLoading(false);
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: "" }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		const updatedErrors = { ...errors };

		updatedErrors.name = data.name ? "" : "Name cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";
		// updatedErrors.address = data.addressObject?.description ? "" : "Address cannot be empty";
		// updatedErrors.bill = data.bill
		// 	? parseFloat(data.bill?.toString() || "") <= 0
		// 		? "Electricity bill must be greater than 0"
		// 		: ""
		// 	: "Electricity bill cannot be empty";

		if (data.password || data.changePassword) {
			updatedErrors.password = validatePassword(data.password);
			updatedErrors.changePassword = validatePassword(data.changePassword);
		}

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdateProfile = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			let newEmail;
			if (user.email?.trim() !== data.email?.trim()) {
				newEmail = data.email;
				setUpdatingEmail(newEmail);
			}

			const formData = new FormData();
			formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("email", data.email ?? "");
			formData.append("address", data.addressObject?.description ?? "");
			formData.append("electricity_usage", data.bill?.toString() ?? "");
			formData.append("phone_no", data.phone ?? "");

			formData.append("current_password", data.password ?? "");
			formData.append("change_password", data.changePassword ?? "");

			await dispatch(updateProfile(formData));

			if (newEmail) {
				// setting the old email in input field, if user decides to close the verify otp dialog the input will display the active previous email of user
				setData((state) => ({ ...state, email: user.email }));
				openOtpDialog();
			} else {
				toast.success("Profile updated successfully!");
			}
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = "A user with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(allRoutes.DASHBOARD);
	};

	const syncOutlookCalendar = async () => {
		// TODO: move these and all other sensitive variables to env file in all servers
		// TODO: replace Shahmeer's client_id and client_secret with Muharem's

		const OUTLOOK_CLIENT_ID = process.env.REACT_APP_OUTLOOK_CLIENT_ID || ""; // "51cb2027-74fc-49e5-9ce9-1d192364991b";
		const OUTLOOK_TENANT_ID = "common";
		const OUTLOOK_REDIRECT_URI = `${window.location.origin}/account-settings`;
		const OUTLOOK_SCOPES = "Calendars.ReadWrite offline_access User.Read"; // e.g., 'openid email profile

		const url = `https://login.microsoftonline.com/${OUTLOOK_TENANT_ID}/oauth2/v2.0/authorize?client_id=${OUTLOOK_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(
			OUTLOOK_REDIRECT_URI,
		)}&scope=${encodeURIComponent(OUTLOOK_SCOPES)}&response_mode=query&state=12345`;

		window.location.href = url;
	};

	const syncGoogleCalendar = async () => {
		const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID || ""; //"268847163908-i8h0pohcdae32ct7ffg5e7070u2g0jq5.apps.googleusercontent.com";
		const REDIRECT_URI = window.location.origin + "/account-settings?google-calendar=true";

		const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/calendar&prompt=consent&access_type=offline`;
		// const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/calendar&access_type=offline`;
		window.location.href = url;
	};

	const generateReferralLink = async () => {
		try {
			const { data } = await getReferralLink();
			console.log("RES", data);
			setReferralLink(data.token || "");
		} catch (error: any) {
			toast.error(error);
		}
	};

	const openOtpDialog = () => setOtpDialog(true);
	const closeOtpDialog = () => setOtpDialog(false);

	return (
		<PageLayout loading={loading} backButtonPath={allRoutes.DASHBOARD}>
			<Typography variant="h5">{textSnippets["account_settings_heading_installer"] || "Account Settings"}</Typography>
			<Typography fontSize={15} mt={10}>
				{textSnippets["update_details_subheading_installer"] || "Update your photo and personal details"}
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdateProfile}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", sm: "340px 1fr" }}
					gap={{ xs: 10, sm: 32 }}
					alignItems="center"
				>
					<Box alignSelf="flex-start">
						<Typography variant="h5">Your Photo</Typography>
						<Typography fontSize={15} mt={10}>
							This will be displayed on your profile
						</Typography>
					</Box>
					<Box>
						<ImageUploader onUpdate={handleSelectImage} imageFile={profilePicture} />
					</Box>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						Name
					</Typography>
					<CustomTextField onChange={handleOnChange} value={data.name} name="name" placeholder="Name" />

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						Email address
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.email}
						error={errors.email}
						name="email"
						type="email"
						placeholder="@example"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						Address
					</Typography>
					<GoogleMapsTextField
						placeholder="Address"
						value={data.addressObject}
						onChange={handleAddress}
						error={errors.address}
					/>

					{/* <Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						Bill
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.bill}
						error={errors.bill?.toString()}
						name="bill"
						type="number"
						placeholder="2500"
						endIcon={<>/Mo</>}
					/> */}

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						Phone Number
					</Typography>
					<MuiPhoneNumber
						defaultCountry={"us"}
						autoComplete="off"
						onChange={(phoneNumber: any) => setData({ ...data, phone: phoneNumber.toString() })}
						fullWidth
						variant="outlined"
						size="small"
						value={data.phone}
						required
						InputLabelProps={{ shrink: true }}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						Current Password
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.password}
						error={errors.password}
						name="password"
						type="password"
						placeholder="********"
						autoComplete="new-password"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, sm: 0 }}>
						New Password
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.changePassword}
						error={errors.changePassword}
						name="changePassword"
						type="password"
						placeholder="********"
						autoComplete="new-password"
					/>

					{(isRepresentativeLoggedIn() || isTechnicianLoggedIn()) && (
						<>
							<Box />
							<Box
								display="flex"
								alignItems={{ xs: "flex-start", md: "center" }}
								gap={10}
								justifyContent="space-between"
								flexDirection={{ xs: "column", md: "row" }}
								sx={{
									bgcolor: colors.primary + "09",
									border: `1.5px dotted ${colors.primary}`,
									padding: 16,
									borderRadius: borderRadius.md,
									"& svg": {
										width: "16px",
										height: "16px",
									},
								}}
							>
								<Typography fontSize={16} color="primary" fontWeight={600}>
									Sync your calendar
								</Typography>
								<Tooltip
									title={
										!!data.refreshToken || !!data.outlookRefreshToken
											? `Your ${data.refreshToken ? "Google" : "Outlook"} calendar has already been synchronized`
											: ""
									}
									placement="top"
									arrow
								>
									<Box
										display="grid"
										// gridTemplateColumns="1fr 1fr"
										gridTemplateColumns="1fr"
										alignItems="center"
										gap={10}
										width={{ xs: "100%", sm: "auto" }}
									>
										{/* <CustomButton
											variant="outlined"
											sx={{ py: 8, px: 14 }}
											onClick={syncOutlookCalendar}
											startIcon={<MicrosoftIcon />}
											disabled={!!data.refreshToken || !!data.outlookRefreshToken}
										>
											Outlook
										</CustomButton> */}
										<CustomButton
											variant="outlined"
											sx={{ py: 8, px: 14 }}
											onClick={syncGoogleCalendar}
											startIcon={<GoogleIcon />}
											disabled={!!data.refreshToken || !!data.outlookRefreshToken}
										>
											Google
										</CustomButton>
									</Box>
								</Tooltip>
							</Box>
						</>
					)}

					<Box />
					<Box
						display="flex"
						alignItems={{ xs: "flex-start", md: "center" }}
						gap={10}
						justifyContent="space-between"
						flexDirection={{ xs: "column", md: "row" }}
						sx={{
							bgcolor: colors.primary + "09",
							border: `1.5px dotted ${colors.primary}`,
							padding: 16,
							borderRadius: borderRadius.md,
							"& > div": {
								width: { xs: "100%", md: "350px !important" },
							},
						}}
					>
						<Typography fontSize={16} color="primary" fontWeight={600}>
							Your referral link
						</Typography>
						{referralLink ? (
							<CustomTextField
								className="referral-link-field"
								value={referralLink}
								endIcon={
									<CopyableText copyableText={referralLink} onCopy={() => toast.success("Link copied to clipboard!")} />
								}
							/>
						) : (
							<CustomButton
								variant="outlined"
								sx={{ py: 8, px: 14, ml: { xs: "unset", md: "auto" } }}
								onClick={generateReferralLink}
							>
								Generate link
							</CustomButton>
						)}
					</Box>

					<Box />
					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">Save Changes</CustomButton>
					</Box>
				</Box>
			</form>

			<OtpVerifyDialog open={otpDialog} onClose={closeOtpDialog} email={updatingEmail} />
		</PageLayout>
	);
};

export default AccountSettings;
