import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { allRoutes } from "../../Routes/AllRoutes";
import PageLayout from "../PageLayout/PageLayout";
import { isAdminLoggedIn, isManagerLoggedIn, isOfficeManagerLoggedIn } from "../../Services/userService";
import { getLeaderBoard } from "../../Services/installersService";
import { useSelector } from "../../Redux/reduxHooks";
import { selectUser } from "../../Redux/Slices/userSlice";
import { TableBlock } from "../Dashboard/Dashboard";
import { roles } from "../../Utils/tokenKeyValue";
import AvatarCell from "../Common/Table/AvatarCell";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";

export const tableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
	{
		text: "Name",
		key: "name",
		customComponent: (props: { text: string; fullObject: any }) => (
			<AvatarCell logo={props.fullObject.ImageUrl} name={props.text} />
		),
	},
	{ text: "Leads", key: "leads", align: "right", sortable: true },
	{ text: "Accepted Proposals", key: "acceptedProposals", sortable: true, align: "right" },
	{ text: "Signed Agreements", key: "signedAgreements", sortable: true, align: "right" },
	{ text: "Sold Revenue", key: "soldRevenue", sortable: true, align: "right" },
	{ text: "Installs", key: "installs", sortable: true, align: "right" },
];

const Representatives = () => {
	const user = useSelector(selectUser);
	const textSnippets = useSelector(selectAllTextSnippets);

	const isAdmin = isAdminLoggedIn();
	const isOfficeManager = isOfficeManagerLoggedIn();
	const isManager = isManagerLoggedIn();

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<{
		admins?: Array<any>;
		managers?: Array<any>;
		officeManagers?: Array<any>;
		customers?: Array<any>;
		representatives?: Array<any>;
	}>({
		admins: [],
		officeManagers: [],
		managers: [],
		customers: [],
		representatives: [],
	});

	useEffect(() => {
		getAllUsers();
	}, [user]);

	const getAllUsers = async () => {
		if (!user.id) return;

		setLoading(true);
		try {
			// if (isAdmin || isOfficeManager) {
			const { data } = await getLeaderBoard(roles.REPRESENTATIVE);
			setData({ representatives: data });
			// } else if (isManager) {

			// 	// TODO :  Replace it with new api of leader board
			// 	const { data } = await getRepresentativesOfManager((user.id || "")?.toString());
			// 	setData({ representatives: data });
			// }
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<PageLayout loading={loading} hideBackButton>
			<TableBlock
				heading={textSnippets['representatives_heading_installer_dashboard_admin_page']  || "Representatives"}
				subHeading={textSnippets['representatives_subheading_installer_dashboard_admin_page'] || `These are all the representatives in this company:`}
				tableData={data.representatives}
				
				addButtonState={isManager ? { managerId: user.id, officeManagerId: user.officeManagerId } : null}
				detailsPagePath={allRoutes.VIEW_REPRESENTATIVE}
				tableHeaders={tableHeaders}
				addButtonText={(isAdmin || isOfficeManager)  ? "Add Representative" : undefined}
				addButtonPath={(isAdmin || isOfficeManager)  ? allRoutes.ADD_REPRESENTATIVE :  undefined}
				emptyStateMessage={textSnippets['no_representatives_message_installer_dashboard_admin_page'] || "There are no representatives present. Please add a representative"}
			/>
		</PageLayout>
	);
};

export default Representatives;
