import { getAuthHeader } from './userService';
import http from "./httpService";

const apiEndpoint  = 'technician'

const TechnicianService = {
	verifyToken: (token: string) => http.get(`${apiEndpoint}/verify-token/` +token ),
	addTechnicianInformation: (data:any) => http.put(`${apiEndpoint}/add-technician-information/` , data , {
		headers: { "Content-Type": "multipart/form-data" },
	} ),
	registerNewTechnician: (data: any) =>
    http.post(`${apiEndpoint}/register-technician`, data, {
        headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
    }),
	getTechnicianSiteSchedules :  (technicianId : string ) => http.get(`${apiEndpoint}/site-schedules/${technicianId}`, {
        headers: { ...getAuthHeader() },
    })
};



export const verifyTechnicianToken = (token : string) => {
	return TechnicianService.verifyToken(token)
};

export const addTechnicianInformation = (data:any) => {
	return TechnicianService.addTechnicianInformation(data)
};

export const registerNewTechnician = (data: any) => {
	return TechnicianService.registerNewTechnician(data);
};


export const getTechnicianSiteSchedules = (technicianId: string) => {
	return TechnicianService.getTechnicianSiteSchedules(technicianId);
};