import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PageLayout from "../PageLayout/PageLayout";
import { allRoutes } from "../../Routes/AllRoutes";
import { getAllTechnicians } from "../../Services/dashboardService";
import TableBlock from "../Common/Table/TableBlock";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { useSelector } from "../../Redux/reduxHooks";
import CustomTableOptions from "../Common/CustomTableOptions";
import { useNavigate } from "react-router-dom";


const Technicians = () => {
	const navigate =  useNavigate();
	

	const [loading, setLoading] = useState<boolean>(false);
	const [data, setData] = useState<Array<any>>([]);
	const textSnippets = useSelector(selectAllTextSnippets);

	const tableHeaders = [
		{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 75 },
		{ text: "Name", key: "name", sortable: true },
		{ text: "Email address", key: "email", sortable: true },
		{ text: "Company", key: "company" },
		{ text: "Email Status", key: "isEmailVerified" },
		{ text: "Calender Synced", key: "calendarSynced" },
		];
	


	useEffect(() => {
		getAllUsers();
	}, []);

	const getAllUsers = async () => {
		setLoading(true);
		try {
			const { data } = await getAllTechnicians();
			setData(data);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	return (
		<PageLayout loading={loading} hideBackButton>
			<TableBlock
				heading={textSnippets['technicians_heading_installer_dashboard_admin_page'] || "Installation Crew"}
				subHeading={textSnippets['technicians_subheading_installer_dashboard_admin_page'] || "These are all the installation crew:"}
				tableData={data}
				addButtonText={textSnippets['add_technician_button_installer_dashboard_admin_page'] || "Add Installation Crew"}
				addButtonPath={allRoutes.ADD_TECHNICIAN}
				detailsPagePath={allRoutes.VIEW_TECHNICIAN}
				tableHeaders={tableHeaders}
				emptyStateMessage={textSnippets['no_technicians_message_installer_dashboard_admin_page'] || "There are no installation crew present. Please add an installation crew"}
				rowsPerPage={10}
			/>
		</PageLayout>
	);
};

export default Technicians;
