import { TextSnippet } from "../Utils/types";
import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "text-snippets";

// =====|  Profile Service  |=====

const TextSnippetsService = {
	getAllTextSnippets : (type : string) => http.get(`${apiEndpoint}/${type}`,{ headers: getAuthHeader() }),

	updateTextSnippets : (data  : TextSnippet[]) => http.put(`${apiEndpoint}`,data ,{ headers: getAuthHeader() }),
	
};


export const updateTextSnippets = (data  : TextSnippet[]) => {
	return TextSnippetsService.updateTextSnippets(data);
};


export const getAllTextSnippets = (type : string) => {
	return TextSnippetsService.getAllTextSnippets(type);
};