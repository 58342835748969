import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { allRoutes } from "../../Routes/AllRoutes";
import DeleteDialog from "../Common/DeleteDialog";
import ProfileHeader from "../Common/ProfileHeader";
import CustomTable from "../Common/Table/CustomTable";
import { toast } from "react-toastify";
import StatusChip from "../Common/StatusChip";
import {
	deleteInstallerById,
	getCustomersOfRepresentative,
	getDetailsOfInstaller,
} from "../../Services/installersService";
import CustomButton from "../Common/CustomButton";
import { isManagerLoggedIn } from "../../Services/userService";
import { Add } from "@mui/icons-material";
import AssignCustomerDialog from "../Dashboard/AssignCustomerDialog";
import PlaceholderForEmptyTable from "../Common/Table/PlaceholderForEmptyTable";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import AssignInstallerToCustomerDialog from "../Dashboard/AssignInstallerToCustomerDialog";
import { roles } from "../../Utils/tokenKeyValue";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CustomTableOptions from "../Common/CustomTableOptions";
import { useSelector } from "../../Redux/reduxHooks";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { borderRadius } from "../../Utils/spacings";
import { selectColors } from "../../Redux/Slices/generalSlice";

export const customerTableHeaders = [
	{ text: "ID", key: "sequentialId", showEllipses: true, maxWidth: 40 },
	{ text: "Name", key: "name", showEllipses: true, maxWidth: 100, sortable: true },
	{ text: "Email address", key: "email", showEllipses: true, maxWidth: 100, sortable: true },
	{
		text: "Phone number",
		key: "phone",
		customComponent: (props: { text: string; fullObject: any }) => (
			<Typography component="span" width="max-content">
				{props.text || "-"}
			</Typography>
		),
	},
	// {
	// 	text: "Email Status",
	// 	key: "isEmailVerified",
	// 	customComponent: (props: any) => (props.fullObject.isEmailVerified ? "Verified" : "Not Verified"),
	// },
	{
		text: "Current Step",
		key: "currentStep",
		customComponent: (props: { text: string; fullObject: any }) => (
			<Box>
				<Typography variant="inherit" color="primary" fontWeight={600} mb={5}>
					{props.text}
				</Typography>
				<StatusChip status={props.fullObject.status} />
			</Box>
		),
		sortable: true,
	},
];

export const downloadPDFFiles = async (urls: any) => {
	const zip = new JSZip();
	urls = urls.filter(Boolean);
	console.log("URLS", urls);

	for (const url of urls) {
		const response = await fetch(url);
		const blob = await response.blob();
		zip.file(url.split("/").pop(), blob);
	}
	zip.generateAsync({ type: "blob" }).then((content) => {
		saveAs(content, "reports.zip");
	});
};

const RepresentativeDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const textSnippets = useSelector(selectAllTextSnippets);
	const colors = useSelector(selectColors);

	const [data, setData] = useState<any>({});
	const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
	const [projects, setProjects] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [assigningCustomer, setAssigningCustomer] = useState<any>(null);
	const [dialogForNewCustomer, setDialogForNewCustomer] = useState<boolean>(false);
	const [dialogForAssignInstaller, setDialogForAssignInstaller] = useState<boolean>(false);
	useEffect(() => {
		window.scrollTo(0, 0);
		getDetails();
	}, []);

	const getDetails = async (onlyGetProject?: boolean) => {
		if (!id) navigate(allRoutes.DASHBOARD);

		setLoading(!onlyGetProject);
		try {
			const { data: userData } = await getDetailsOfInstaller((id || "")?.toString());
			setData(userData);

			const { data: assignedCustomers } = await getCustomersOfRepresentative((userData._id || "")?.toString());
			setProjects(assignedCustomers || []);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const openDeleteDialog = () => setDeleteDialog(true);
	const closeDeleteDialog = () => setDeleteDialog(false);

	const openAssigningDialog = () => setDialogForNewCustomer(true);
	const closeAssigningDialog = () => setDialogForNewCustomer(false);
	const openAssignInstallerDialog = (data: any) => {
		setAssigningCustomer(data);
		setDialogForAssignInstaller(true);
	};
	const closeAssignInstallerDialog = () => setDialogForAssignInstaller(false);

	const handleEdit = () => {
		navigate(allRoutes.EDIT_REPRESENTATIVE.replace(":id", (id || "")?.toString()));
	};

	const handleDelete = async () => {
		try {
			const { data: res } = await deleteInstallerById(data._id || "");
			if (res === "Account deleted") {
				toast.success("Representative deleted successfully!");
				if (isManagerLoggedIn()) {
					navigate(allRoutes.DASHBOARD);
				} else {
					navigate(allRoutes.REPRESENTATIVES);
				}
			}
		} catch (error: any) {
			toast.error(error);
		}
	};

	const headers = [
		...customerTableHeaders,
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,

			customComponent: ({ fullObject }: { fullObject: any }) => {
				const notReportsPresent = !fullObject?.summaryProposalPdfLink && !fullObject?.billAnalysisPdfLink;
				return (
					<CustomTableOptions
						menuOptions={[
							{
								text: "Reassign",
								onClick: () =>
									openAssignInstallerDialog({ ...fullObject, showWarning: fullObject.assignedManager ? true : false }),
							},
							{
								text: "View Report",
								onClick: () => {
									if (!notReportsPresent) {
										downloadPDFFiles([fullObject?.summaryProposalPdfLink, fullObject?.billAnalysisPdfLink]);
									}
								},
								disabled: notReportsPresent,
								tooltip: notReportsPresent ? "Reports have not been generated for this customer" : "Download Reports",
							},
						]}
					/>
				);
			},
		},
	];

	// const assignedCustomerHeaders = [
	// 	...customerTableHeaders,
	// 	{
	// 		text: "",
	// 		key: "name",
	// 		align: "right",
	// 		notClickable: true,

	// 		customComponent: ({ fullObject }: { fullObject: any }) => {
	// 			const notReportsPresent = !fullObject?.summaryProposalPdfLink && !fullObject?.billAnalysisPdfLink;
	// 			return (
	// 				<CustomTableOptions
	// 					menuOptions={[
	// 						{
	// 							text: "Reassign",
	// 							onClick: () =>
	// 								openAssignInstallerDialog({ ...fullObject, showWarning: fullObject.assignedManager ? true : false }),
	// 						},
	// 						{
	// 							text: "View Report",
	// 							onClick: () => {
	// 								if (!notReportsPresent) {
	// 									downloadPDFFiles([fullObject?.summaryProposalPdfLink, fullObject?.billAnalysisPdfLink]);
	// 								}
	// 							},
	// 							disabled: notReportsPresent,
	// 							tooltip: notReportsPresent ? "Reports have not been generated for this customer" : "Download Reports",
	// 						},
	// 					]}
	// 				/>
	// 			);
	// 		},
	// 	},
	// ];

	const fields = [
		{ text: "Name", key: "name" },
		{ text: "Email address", key: "email" },
		{ text: "Address", key: "address" },
		{ text: "Phone Number", key: "phone_no" },
		{
			text: "Email Status",
			key: "isEmailVerified",
			customComponent: () => (data.isEmailVerified ? "Verified" : "Not Verified"),
		},

		{
			text: "Dashboard",
			key: "name",
			// customComponent: (props: any) => (
			customComponent: () => (
				<CustomButton
					onClick={() => navigate(allRoutes.VIEW_REPRESENTATIVE_DASHBOARD.replace(":id", data.sequentialId))}
					variant="text"
					sx={{ padding: 5, minWidth: "unset", width: "max-content" }}
				>
					<Tooltip title={"This will open the dashboard view of this representative"}>
						<DashboardOutlinedIcon />
					</Tooltip>
				</CustomButton>
			),
			errorMessage: "Not Given",
		},
	];

	return (
		<PageLayout
			loading={loading}
		// backButtonPath={
		// 	isAdminLoggedIn() || isOfficeManagerLoggedIn()
		// 		? allRoutes.VIEW_MANAGER.replace(":id", data.managerId || "")
		// 		: allRoutes.DASHBOARD
		// }
		>
			<ProfileHeader
				data={data}
				userType="Representative"
				handleEdit={handleEdit}
				handleDelete={openDeleteDialog}
				disableDeleteButton={!!projects?.length}
				tooltipText="You cannot delete representative account that have customer assigned to them"
			/>

			<Box
				display="grid"
				gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
				gap={{ xs: 10, md: 32 }}
				alignItems="center"
				mt={45}
			>
				{fields?.map((field: any) => (
					<React.Fragment key={field.key}>
						<Typography variant="h6">{field.text}</Typography>
						{field.customComponent ? (
							field.customComponent({ text: data?.[field.key] })
						) : field.subValues ? (
							<Box
								display="grid"
								alignItems="flex-start"
								gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
								gap={{ xs: 10, md: 32 }}
								sx={{
									padding: 16,
									bgcolor: colors.primary + "09",
									border: `1.5px dotted ${colors.primary}`,
									borderRadius: borderRadius.md, // TODO for Waleed: create a common component for this purpleBox and use it in all places e.g, AccountSettings, ConfigurationsPage, EditCustomer, CustomerDetails
									minWidth: { sm: "auto", xs: "100%" },
								}}
							>
								{field.subValues?.map((subField: any) => (
									<Box key={subField.label}>
										<Typography fontSize={12} fontWeight={600} mb={3}>
											{subField.label}
										</Typography>
										<Typography>{subField.value}</Typography>
									</Box>
								))}
							</Box>
						) : (
							<Typography>{data?.[field.key] || field.errorMessage || "Not given"}</Typography>
						)}
					</React.Fragment>
				))}
			</Box>

			<Divider sx={{ my: { xs: 16, md: 42 } }} />
			<Box>
				<Typography variant="h5">
					{textSnippets["assigned_customers_heading_representative_profile"] || "Assigned Customers"}
				</Typography>

				<Box
					display="flex"
					alignItems={{ xs: "stretch", md: "center" }}
					justifyContent="space-between"
					flexDirection={{ xs: "column", md: "row" }}
					gap={12}
					mb={32}
				>
					<Typography fontSize={16} mt={10}>
						These are all the customers assigned to {data.name}
					</Typography>
					<Box
						display="flex"
						alignItems={{ xs: "stretch", md: "center" }}
						justifyContent="flex-end"
						gap={12}
						flexDirection={{ xs: "column", md: "row" }}
					>
						<CustomButton
							sx={{ height: 40.13, minWidth: "max-content" }}
							startIcon={<Add />}
							onClick={openAssigningDialog}
						>
							{textSnippets["assign_customer_button_representative_profile"] || "Assign Customer"}
						</CustomButton>
					</Box>
				</Box>

				{projects?.length ? (
					<CustomTable headers={headers} rows={projects} detailsPagePath={allRoutes.VIEW_CUSTOMER} />
				) : (
					<PlaceholderForEmptyTable
						message={`No customer is currently assigned to ${data?.name}. Please assign a customer.`}
					/>
				)}
			</Box>
			<AssignInstallerToCustomerDialog
				onSuccess={() => getDetails(true)}
				selectedCustomer={assigningCustomer}
				currentSelection={data}
				open={dialogForAssignInstaller}
				onClose={closeAssignInstallerDialog}
				type={roles.REPRESENTATIVE}
			/>
			<DeleteDialog
				open={deleteDialog}
				onClose={closeDeleteDialog}
				userType="Representative"
				user={data}
				onDelete={handleDelete}
			/>

			<AssignCustomerDialog
				open={dialogForNewCustomer}
				onClose={closeAssigningDialog}
				onSuccess={() => getDetails(true)}
				// currentSelection={data}
				installerDetails={data}
			/>
		</PageLayout>
	);
};

export default RepresentativeDetails;
