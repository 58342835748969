import React, { FormEvent, useEffect, useState } from "react";
import { addTechnicianInformation, verifyTechnicianToken } from "../../Services/technicianService";
import { toast } from "react-toastify";
import { Params, useNavigate, useParams } from "react-router-dom";
import { UserState } from "../../Redux/Slices/userSlice";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";
import { validatePassword } from "../../Utils/utils";
import { allRoutes } from "../../Routes/AllRoutes";
import { Box, Divider, Typography } from "@mui/material";
import ImageUploader from "../Common/ImageUploader";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import CustomButton from "../Common/CustomButton";
import CustomDialog from "../Common/CustomDialog";
import { selectColors } from "../../Redux/Slices/generalSlice";
import Loader from "../Common/Loader";
import { useSelector } from "../../Redux/reduxHooks";

interface AccountSettingsData extends UserState {
	confirmPassword?: string;
	addressObject?: PlaceType | null;
}

const defaultData = {
	name: "",
	addressObject: null,
	phone: "",
	password: "",
	confirmPassword: "",
};

const RegisterTechnicianPage = () => {
	const { token }: Params = useParams();
	const navigate = useNavigate();

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [senderName, setSenderName] = useState<string>("Sender");
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");
	const colors = useSelector(selectColors);

	useEffect(() => {
		verifyToken();
	}, [token, history]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setData((state) => ({ ...state, [name]: value }));
		setErrors((state) => ({ ...state, [name]: name === "password" ? validatePassword(value) : "" }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		const updatedErrors = { ...errors };
		updatedErrors.name = data.name ? "" : "Name cannot be empty";
		// updatedErrors.address = data.addressObject?.description ? "" : "Address cannot be empty";
		updatedErrors.password = validatePassword(data.password);
		updatedErrors.confirmPassword = data.confirmPassword
			? data.confirmPassword !== data.password
				? "Password does not match"
				: ""
			: "Confirm password cannot be empty";

		setErrors(updatedErrors);
		return !Object.values(updatedErrors).find(Boolean);
	};

	const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;

		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("address", data.addressObject?.description ?? "");
			formData.append("phone_no", data.phone ?? "");
			formData.append("password", data.password ?? "");
			formData.append("token", token ?? "");
			await addTechnicianInformation(formData);

			toast.success("Installer Crew added successfully!");
			navigate(allRoutes.HOME);
		} catch (error: any) {
			console.log(error);
			toast.error(error.message);
		}
		setLoading(false);
	};

	const verifyToken = async () => {
		if (!token) return;
		try {
			const data = await verifyTechnicianToken(token);
			console.log(data);
			setSenderName(data.data.user.createdBy.name);
			return name;
		} catch (error: any) {
			toast.error(error.message);
			navigate(allRoutes.HOME);
		}
	};

	return (
		<>
			<CustomDialog open={true} maxWidth={"900px"}>
				<Loader open={loading} />
				<Typography variant="h2" fontSize={{ sm: 32, xs: 22 }} my={16} textAlign={{ sm: "center", xs: "left" }}>
					Add Your Information
				</Typography>
				<Typography fontSize={{ sm: 16, xs: 14 }} textAlign={{ sm: "center", xs: "left" }} color={colors.textMid}>
					You are invited as an installation crew role by <b>{senderName} </b> <br />
				</Typography>
				<Divider sx={{ mt: 14, mb: 24 }} />

				<form onSubmit={handleUpdate}>
					<Box
						display="grid"
						gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
						gap={{ xs: 10, md: 32 }}
						alignItems="center"
					>
						<Box alignSelf="flex-start">
							<Typography variant="h5">Photo</Typography>
							<Typography fontSize={15} mt={10}>
								This will be displayed on installation crew's profile
							</Typography>
						</Box>
						<Box>
							<ImageUploader onUpdate={handleSelectImage} imageFile={profilePicture} />
						</Box>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
							Name
							<Asterisk />
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.name}
							name="name"
							placeholder="Name"
							error={errors.name}
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
							Address
						</Typography>
						<GoogleMapsTextField
							placeholder="Address"
							value={data.addressObject}
							onChange={handleAddress}
							error={errors.address}
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
							Phone Number
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.phone}
							error={errors.phone}
							name="phone"
							placeholder="+1"
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
							Password
							<Asterisk />
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.password}
							error={errors.password}
							name="password"
							type="password"
							placeholder="********"
							autoComplete="new-password"
						/>

						<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
							Confirm Password
							<Asterisk />
						</Typography>
						<CustomTextField
							onChange={handleOnChange}
							value={data.confirmPassword}
							error={errors.confirmPassword}
							name="confirmPassword"
							type="password"
							placeholder="********"
							autoComplete="new-password"
						/>

						<Box />
						<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
							<CustomButton type="submit">Create Account</CustomButton>
						</Box>
					</Box>
				</form>
			</CustomDialog>
		</>
	);
};

export default RegisterTechnicianPage;
