import { FormEvent, useEffect, useState } from "react";
import { UserState } from "../../Redux/Slices/userSlice";
import CustomButton from "../Common/CustomButton";
import CustomTextField, { Asterisk } from "../Common/CustomTextField";
import { Box, Divider, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ImageUploader from "../Common/ImageUploader";
import * as EmailValidator from "email-validator";
import GoogleMapsTextField, { PlaceType } from "../Common/GoogleMapsTextField";
import PageLayout from "../PageLayout/PageLayout";
import StatusChip from "../Common/StatusChip";
import { selectColors } from "../../Redux/Slices/generalSlice";
import { borderRadius } from "../../Utils/spacings";
import { allRoutes } from "../../Routes/AllRoutes";
import {
	changeTechnician,
	getAllTechnicians,
	getAssignedInstaller,
	getAssignedTechnician,
	getCustomerDetails,
	updateCustomer,
} from "../../Services/dashboardService";
import CustomDropdown from "../Common/CustomDropdown";
import MuiPhoneNumber from "material-ui-phone-number";
import { leadStatuses } from "../../Utils/enums";
import { useSelector } from "../../Redux/reduxHooks";

interface AccountSettingsData extends UserState {
	changePassword?: string;
	addressObject?: PlaceType | null;
	currentStep?: string;
	status?: string;
	technicianId?: string;
	installerId?: string;
	electricity_bill?: string;
	supplyCharges?: string;
	deliveryCharges?: string;
	uploadedBillCost?: string;
	leadStatus?: string;
	monthlyElectricityUsage?: { [key: string]: string };
	lastName?: string
}

const defaultData: AccountSettingsData = {
	name: "",
	lastName: "",
	email: "",
	addressObject: null,
	bill: "",
	phone: "",
	password: "",
	changePassword: "",
	technicianId: "",
	installerId: "",
	leadStatus: "",
};

const EditCustomer = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	// if (false || false) {
	// 	defaultData = {
	// 		...defaultData,
	// 		supplyCharges: "",
	// 		deliveryCharges: "",
	// 		uploadedBillCost: "",
	// 		monthlyElectricityUsage: {
	// 			January: "",
	// 			February: "",
	// 			March: "",
	// 			April: "",
	// 			May: "",
	// 			June: "",
	// 			July: "",
	// 			August: "",
	// 			September: "",
	// 			October: "",
	// 			November: "",
	// 			December: "",
	// 		},
	// 	};
	// }

	const [data, setData] = useState<AccountSettingsData>(defaultData);
	const [mongoId, setMongoId] = useState<string | null>(null);
	const [errors, setErrors] = useState<AccountSettingsData>(defaultData);
	const [loading, setLoading] = useState<boolean>(false);
	const [profilePicture, setProfilePicture] = useState<any>("");
	const [allTechnicians, setAllTechnicians] = useState<Array<any>>([]);
	const colors = useSelector(selectColors);

	useEffect(() => {
		getDetails();
	}, []);

	const getDetails = async () => {
		if (!id) navigate(allRoutes.CUSTOMERS);

		setLoading(true);
		try {
			const { data: userData }: any = await getCustomerDetails((id || "")?.toString());

			const [{ data: myInstaller }, { data: myTechnician }, { data: technicians }] = await Promise.all([
				getAssignedInstaller((userData?._id || "")?.toString()),
				getAssignedTechnician((userData?._id || "")?.toString()),
				getAllTechnicians(),
			]);

			console.log("Assigned Installer: ", myInstaller);
			console.log("Assigned Technician: ", myTechnician);

			const currentData: any = {
				name: userData?.name || "",
				lastName: userData?.lastName || "",
				email: userData?.email || "",
				addressObject: {
					description: userData.address,
					structured_formatting: {
						main_text: userData.address,
						secondary_text: userData.address,
					},
				},
				bill: userData?.bill || "",
				phone: userData?.phone_no || "",
				status: userData?.status || "",
				currentStep: userData?.currentStep || "",
				leadStatus: userData?.leadStatus || "",
				technicianId: myTechnician?._id || "",
				installerId: myInstaller?._id || "",
			};

			setData(currentData);
			setMongoId(userData._id);
			setProfilePicture(userData?.image || "");

			const techniciansValues = technicians.map((item: any) => ({ ...item, value: item._id, text: item.name }));
			setAllTechnicians(techniciansValues);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const handleDropdown = (value: string, key: string) => {
		setData((state) => ({ ...state, [key]: value }));
	};

	const handleAddress = (value: PlaceType | null) => {
		setData((state) => ({ ...state, addressObject: value }));
		setErrors((state) => ({ ...state, address: "" }));
	};

	const handleSelectImage = (image: any) => {
		setProfilePicture(image);
	};

	const validateData = () => {
		console.log({ errors });
		const updatedErrors: any = { ...errors };

		updatedErrors.name = data.name ? "" : "First Name cannot be empty";
		updatedErrors.lastName = data.lastName ? "" : "Last Name cannot be empty";
		updatedErrors.phone = data.phone ? "" : "Phone number cannot be empty";
		updatedErrors.email = data.email
			? !EmailValidator.validate(data.email)
				? "Enter a valid email"
				: ""
			: "Email cannot be empty";
		updatedErrors.address = data.addressObject?.description ? "" : "Address cannot be empty";
		updatedErrors.bill = data.bill
			? parseFloat(data.bill?.toString() || "") <= 0
				? "Electricity bill must be greater than 0"
				: ""
			: "Electricity bill cannot be empty";

		setErrors(updatedErrors);
		let foundError = Object.values(updatedErrors).find(Boolean);
		console.log(foundError);
		if (typeof foundError === "object") {
			foundError = Object.values(updatedErrors.monthlyElectricityUsage).find(Boolean);
		}
		return !foundError;
	};

	const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!validateData()) return;
		setLoading(true);
		try {
			const formData = new FormData();
			formData.append("ImageUrl", profilePicture ?? "");
			formData.append("name", data.name ?? "");
			formData.append("lastName", data.lastName ?? "");
			formData.append("email", data.email ?? "");
			formData.append("address", data.addressObject?.description ?? "");
			formData.append("electricity_usage", data.bill?.toString() ?? "");
			formData.append("phone_no", data.phone ?? "");
			formData.append("leadStatus", data.leadStatus ?? "");

			console.log({ mongoId });
			await updateCustomer(mongoId || "", formData);

			if (data.technicianId) {
				const payload = {
					userId: mongoId,
					technicianId: data.technicianId,
				};
				await changeTechnician(mongoId || "", payload);
			}

			toast.success("Customer information updated successfully!");
			navigate(-1);
		} catch (error: any) {
			let specificError = error;
			if (specificError.includes("This email already exists.")) {
				specificError = "A user with this email already exists.";
				setErrors({ ...errors, email: specificError });
			} else {
				toast.error(specificError);
			}
		}
		setLoading(false);
	};

	const handleCancel = () => {
		navigate(-1);
	};
	const handleOnChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		isSubValue: boolean,
		parentKey?: keyof AccountSettingsData,
	) => {
		const { name, value } = e.target;
		if (isSubValue && parentKey && typeof data[parentKey] === "object" && data[parentKey] !== null) {
			setData((prevState) => ({
				...prevState,
				[parentKey]: {
					...(prevState[parentKey] as Record<string, any>),
					[name]: value,
				},
			}));
		} else {
			setData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}

		setErrors((prevState) => ({
			...prevState,
			[name]: "",
		}));
	};

	return (
		<PageLayout loading={loading}>
			<Typography variant="h5">Edit Customer</Typography>
			<Typography fontSize={15} mt={10}>
				Update photo and personal details of customer
			</Typography>
			<Divider sx={{ mt: 14, mb: 24 }} />

			<form onSubmit={handleUpdate}>
				<Box
					display="grid"
					gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
					gap={{ xs: 10, md: 32 }}
					alignItems="start"
				>
					<Box alignSelf="flex-start">
						<Typography variant="h5">Photo</Typography>
						<Typography fontSize={15} mt={10}>
							This will be displayed on customer's profile
						</Typography>
					</Box>
					<Box>
						<ImageUploader onUpdate={handleSelectImage} imageFile={profilePicture} />
					</Box>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						First Name
						<Asterisk />
					</Typography>
					<CustomTextField onChange={handleOnChange} value={data.name} error={errors.name} name="name" placeholder="First Name" />
					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Last Name
						<Asterisk />
					</Typography>
					<CustomTextField onChange={handleOnChange} value={data.lastName} error={errors.lastName} name="lastName" placeholder="Last Name" />

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Email address
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.email}
						error={errors.email}
						name="email"
						type="email"
						placeholder="@example"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Address
						<Asterisk />
					</Typography>
					<GoogleMapsTextField
						placeholder="Address"
						value={data.addressObject}
						onChange={handleAddress}
						error={errors.address}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Electricity Bill
						<Asterisk />
					</Typography>
					<CustomTextField
						onChange={handleOnChange}
						value={data.bill}
						error={errors.bill?.toString()}
						name="bill"
						type="number"
						placeholder="2500"
						endIcon={<>/Mo</>}
						disabled={true}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Phone Number
						<Asterisk />
					</Typography>
					<MuiPhoneNumber
						defaultCountry={"us"}
						autoComplete="off"
						onChange={(phoneNumber: any) => {
							setData({ ...data, phone: phoneNumber.toString() });
							if (errors.phone) setErrors({ ...errors, phone: "" });
						}}
						error={!!errors.phone}
						helperText={errors.phone}
						fullWidth
						variant="outlined"
						size="small"
						value={data.phone}
						required
						InputLabelProps={{ shrink: true }}
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Assigned Installation Crew
					</Typography>
					<CustomDropdown
						options={allTechnicians}
						value={data.technicianId}
						onChange={(value: string) => handleDropdown(value, "technicianId")}
						minWidth="100%"
					/>

					{/* <CustomDropdown
						options={allTechnicians}
						value={data.technicianId}
						onChange={(value: string) => handleDropdown(value, "technicianId")}
						minWidth="100%"
					/> */}

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Lead Status
					</Typography>
					<CustomDropdown
						options={Object.values(leadStatuses).map((val) => ({ value: val, text: val }))}
						value={data.leadStatus}
						onChange={(value: string) => handleDropdown(value, "leadStatus")}
						minWidth="100%"
					/>

					<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
						Project Step
					</Typography>
					<Box
						display="flex"
						alignItems={{ xs: "flex-start", md: "center" }}
						gap={10}
						justifyContent="flex-start"
						flexDirection={{ xs: "column", md: "row" }}
						sx={{
							bgcolor: colors.primary + "09",
							border: `1.5px dotted ${colors.primary}`,
							padding: 16,
							borderRadius: borderRadius.md,
						}}
					>
						<Typography fontSize={16} color="primary" fontWeight={600}>
							{data.currentStep}
						</Typography>
						<StatusChip status={data.status} />
						<CustomButton
							variant="outlined"
							sx={{ py: 8, px: 14, ml: { xs: "unset", md: "auto" } }}
							onClick={() => navigate(allRoutes.UPDATE_STATUS?.replace(":id", id || ""))}
						>
							Change Status
						</CustomButton>
					</Box>

					{data.electricity_bill && (false || false) && (
						<>
							<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
								Supply Charges
								<Asterisk />
							</Typography>
							<CustomTextField
								onChange={handleOnChange}
								value={data.supplyCharges}
								error={errors.supplyCharges?.toString()}
								name="supplyCharges"
								type="number"
								placeholder="20"
							/>
							<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
								Delivery Charges
								<Asterisk />
							</Typography>
							<CustomTextField
								onChange={handleOnChange}
								value={data.deliveryCharges}
								error={errors.deliveryCharges?.toString()}
								name="deliveryCharges"
								type="number"
								placeholder="20"
							/>
							<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }}>
								Uploaded Bill Cost
								<Asterisk />
							</Typography>
							<CustomTextField
								onChange={handleOnChange}
								value={data.uploadedBillCost}
								error={errors.uploadedBillCost?.toString()}
								name="uploadedBillCost"
								type="number"
								placeholder="200"
							/>

							<Typography variant="h6" fontSize={18} mt={{ xs: 12, md: 0 }} alignSelf="center">
								Monthly Electricity Usage
								<Asterisk />
							</Typography>
							{/* 
							<Box
								display="grid"
								alignItems="flex-start"
								gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
								gap={{ xs: 10, md: 32 }}
								sx={{
									padding: 16,
									bgcolor: colors.primary + "09",
									border: `1.5px dotted ${colors.primary}`,
									borderRadius: borderRadius.md,
								}}
							>
								{data.monthlyElectricityUsage &&
									Object.keys(data.monthlyElectricityUsage).map((month) => (
										<CustomTextField
											key={month}
											label={month}
											type="number"
											name={month}
											error={(errors.monthlyElectricityUsage ? errors.monthlyElectricityUsage[month] : "") as string}
											value={data.monthlyElectricityUsage ? data.monthlyElectricityUsage[month] : ""}
											placeholder="Enter usage"
											onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
												handleOnChange(e, true, "monthlyElectricityUsage")
											}
											onFocus={(e: any) =>
												e.target.addEventListener("wheel", (e: any) => e.preventDefault(), { passive: false })
											}
										/>
									))}
							</Box>
*/}
						</>
					)}
					<Box />

					<Box display="flex" alignItems="center" justifyContent="flex-end" gap={20}>
						<CustomButton variant="outlined" color="secondary" onClick={handleCancel}>
							Cancel
						</CustomButton>
						<CustomButton type="submit">Save Changes</CustomButton>
					</Box>
				</Box>
			</form>
		</PageLayout>
	);
};

export default EditCustomer;
