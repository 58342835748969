import { useNavigate, useParams } from "react-router-dom";
import PageLayout from "../PageLayout/PageLayout";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { allRoutes } from "../../Routes/AllRoutes";
import DeleteDialog from "../Common/DeleteDialog";
import ProfileHeader from "../Common/ProfileHeader";
import CustomTable from "../Common/Table/CustomTable";
import { toast } from "react-toastify";
import { tableHeaders } from "../Dashboard/Dashboard";
import CustomButton from "../Common/CustomButton";
import { Add } from "@mui/icons-material";
import {
	deleteInstallerById,
	getAssignedCustomersByInstallerId,
	getDetailsOfInstaller,
	getRepresentativesOfManager,
} from "../../Services/installersService";
import AssignRepresentativeDialog from "../Dashboard/AssignRepresentativeDialog";
import { isOfficeManagerLoggedIn } from "../../Services/userService";
import PlaceholderForEmptyTable from "../Common/Table/PlaceholderForEmptyTable";
import AssignCustomerDialog from "../Dashboard/AssignCustomerDialog";
import { roles } from "../../Utils/tokenKeyValue";
import AssignInstallerToCustomerDialog from "../Dashboard/AssignInstallerToCustomerDialog";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CustomTableOptions from "../Common/CustomTableOptions";
import { selectAllTextSnippets } from "../../Redux/Slices/textSnippetsSlice";
import { useSelector } from "../../Redux/reduxHooks";
import { borderRadius } from "../../Utils/spacings";
import { selectColors } from "../../Redux/Slices/generalSlice";

const ManagerDetails = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const textSnippets = useSelector(selectAllTextSnippets);
	const colors = useSelector(selectColors);

	const [data, setData] = useState<any>({});
	const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
	const [projects, setProjects] = useState<Array<any>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [assigningCustomer, setAssigningCustomer] = useState<any>(null);
	const [dialogForNewCustomer, setDialogForNewCustomer] = useState<boolean>(false);
	const [assignedCustomers, setAssignedCustomer] = useState<Array<any>>([]);
	const [assignRepresentativeDialog, setAssignRepresentativeDialog] = useState<boolean>(false);
	const [dialogForAssignInstaller, setDialogForAssignInstaller] = useState<boolean>(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		getDetails();
	}, []);

	const getDetails = async (onlyGetProject?: boolean) => {
		if (!id) navigate(allRoutes.DASHBOARD);

		setLoading(!onlyGetProject);
		try {
			let profileData;
			if (!onlyGetProject) {
				const { data: userData }: any = await getDetailsOfInstaller((id || "")?.toString());
				profileData = userData;
				setData(userData);

				if (!userData) {
					toast.error("Incorrect manager id");
					return navigate(allRoutes.DASHBOARD);
				}
			}

			const { data: customers } = await getAssignedCustomersByInstallerId(
				roles.MANAGER,
				(profileData._id || "")?.toString(),
			);

			const { data: assignedCustomers } = await getRepresentativesOfManager((profileData._id || "")?.toString());
			setAssignedCustomer(customers || []);
			setProjects(assignedCustomers || []);
		} catch (error: any) {
			toast.error(error);
		}
		setLoading(false);
	};

	const openDeleteDialog = () => setDeleteDialog(true);
	const closeDeleteDialog = () => setDeleteDialog(false);
	const openAssignInstallerDialog = (data: any) => {
		setAssigningCustomer(data);
		setDialogForAssignInstaller(true);
	};
	const closeAssignInstallerDialog = () => setDialogForAssignInstaller(false);

	const handleEdit = () => {
		navigate(allRoutes.EDIT_MANAGER.replace(":id", (id || "")?.toString()));
	};

	const handleDelete = async () => {
		try {
			const { data: res } = await deleteInstallerById(data._id || "");
			if (res === "Account deleted") {
				toast.success("Manager deleted successfully!");

				if (isOfficeManagerLoggedIn()) {
					navigate(allRoutes.DASHBOARD);
				} else {
					navigate(allRoutes.MANAGERS);
				}
			}
		} catch (error: any) {
			toast.error(error);
		}
	};

	const openAssignRepresentativeDialog = (props: { text: string; id: string }) => {
		setAssigningCustomer(props);
		setAssignRepresentativeDialog(true);
	};
	const closeAssignRepresentativeDialog = () => setAssignRepresentativeDialog(false);
	const openAssigningDialog = () => setDialogForNewCustomer(true);
	const closeAssigningDialog = () => setDialogForNewCustomer(false);
	const headers: any = [
		...tableHeaders,
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: { id: string; text: string; sequentialId: any }) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "Details",
							onClick: () => navigate(allRoutes.VIEW_REPRESENTATIVE.replace(":id", props.sequentialId)),
						},
						{
							text: "Reassign",
							onClick: () => openAssignRepresentativeDialog(props),
						},
					]}
				/>
			),
		},
	];

	const assignedCustomerHeaders = [
		...tableHeaders,
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,

			customComponent: ({ fullObject }: { fullObject: any }) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "Reassign",
							onClick: () =>
								openAssignInstallerDialog({ ...fullObject, showWarning: fullObject.assignedManager ? true : false }),
						},
					]}
				/>
			),
		},
	];

	const fields = [
		{ text: "Name", key: "name" },
		{ text: "Email address", key: "email" },
		{ text: "Address", key: "address" },
		{ text: "Phone Number", key: "phone_no" },
		{
			text: "Email Status",
			key: "isEmailVerified",
			customComponent: () => (data.isEmailVerified ? "Verified" : "Not Verified"),
		},
		{
			text: "Dashboard",
			key: "name",
			// customComponent: (props: any) => (
			customComponent: () => (
				<CustomButton
					onClick={() => navigate(allRoutes.VIEW_MANAGER_DASHBOARD.replace(":id", data.sequentialId))}
					variant="text"
					sx={{ padding: 5, minWidth: "unset", width: "max-content" }}
				>
					<Tooltip title={"This will open the dashboard view of this manager"}>
						<DashboardOutlinedIcon />
					</Tooltip>
				</CustomButton>
			),
			errorMessage: "Not Given",
		},
	];

	return (
		<PageLayout
			loading={loading}
			backButtonPath={
				allRoutes.MANAGERS
				// isAdminLoggedIn()
				// 	? allRoutes.VIEW_OFFICE_MANAGER.replace(":id", data.officeManagerId || "")
				// 	: allRoutes.DASHBOARD
			}
		>
			<ProfileHeader
				data={data}
				userType="Manager"
				handleEdit={handleEdit}
				handleDelete={openDeleteDialog}
				disableDeleteButton={!!projects?.length}
				tooltipText="You cannot delete manager account that have representatives assigned to them"
			/>

			<Box
				display="grid"
				gridTemplateColumns={{ xs: "1fr", md: "340px 1fr" }}
				gap={{ xs: 10, md: 32 }}
				alignItems="center"
				mt={45}
			>
				{fields?.map((field: any) => (
					<React.Fragment key={field.key}>
						<Typography variant="h6">{field.text}</Typography>
						{field.customComponent ? (
							field.customComponent({ text: data?.[field.key] })
						) : field.subValues ? (
							<Box
								display="grid"
								alignItems="flex-start"
								gridTemplateColumns={{ xs: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
								gap={{ xs: 10, md: 32 }}
								sx={{
									padding: 16,
									bgcolor: colors.primary + "09",
									border: `1.5px dotted ${colors.primary}`,
									borderRadius: borderRadius.md, // TODO for Waleed: create a common component for this purpleBox and use it in all places e.g, AccountSettings, ConfigurationsPage, EditCustomer, CustomerDetails
									minWidth: { sm: "auto", xs: "100%" },
								}}
							>
								{field.subValues?.map((subField: any) => (
									<Box key={subField.label}>
										<Typography fontSize={12} fontWeight={600} mb={3}>
											{subField.label}
										</Typography>
										<Typography>{subField.value}</Typography>
									</Box>
								))}
							</Box>
						) : (
							<Typography>{data?.[field.key] || field.errorMessage || "Not given"}</Typography>
						)}
					</React.Fragment>
				))}
			</Box>

			<Divider sx={{ my: { xs: 16, md: 42 } }} />
			<Box>
				<Typography variant="h5">
					{textSnippets["assigned_representatives_heading_manager_profile"] || "Assigned Representatives"}
				</Typography>

				<Box
					display="flex"
					alignItems={{ xs: "stretch", md: "center" }}
					justifyContent="space-between"
					flexDirection={{ xs: "column", md: "row" }}
					gap={12}
					mb={32}
				>
					<Typography fontSize={16}>These are all the representatives assigned to {data.name}:</Typography>
					<Box
						display="flex"
						alignItems={{ xs: "stretch", md: "center" }}
						justifyContent="flex-end"
						gap={12}
						flexDirection={{ xs: "column", md: "row" }}
					>
						<CustomButton
							sx={{ height: 40.13, minWidth: "max-content" }}
							startIcon={<Add />}
							onClick={() =>
								navigate(allRoutes.ADD_REPRESENTATIVE, {
									state: {
										managerId: data?._id,
										officeManagerId: data?.officeManagerId,
										sequentialId: data?.sequentialId,
									},
								})
							}
						>
							{textSnippets["add_representative_button_manager_profile"] || "Add Representative"}
						</CustomButton>
					</Box>
				</Box>

				{projects?.length ? (
					<CustomTable headers={headers} rows={projects} detailsPagePath={allRoutes.VIEW_REPRESENTATIVE} />
				) : (
					<PlaceholderForEmptyTable
						message={`No representative is currently assigned to ${data?.name}. Please add a representative.`}
					/>
				)}
			</Box>

			<Divider sx={{ my: { xs: 16, md: 42 } }} />
			<Box>
				<Typography variant="h5">
					{textSnippets["assigned_customers_heading_manager_profile"] || "Assigned Customers"}
				</Typography>

				<Box
					display="flex"
					alignItems={{ xs: "stretch", md: "center" }}
					justifyContent="space-between"
					flexDirection={{ xs: "column", md: "row" }}
					gap={12}
					mb={32}
				>
					<Typography fontSize={16} mt={10}>
						These are all the customers assigned to {data.name}
					</Typography>
					<Box
						display="flex"
						alignItems={{ xs: "stretch", md: "center" }}
						justifyContent="flex-end"
						gap={12}
						flexDirection={{ xs: "column", md: "row" }}
					>
						<CustomButton
							sx={{ height: 40.13, minWidth: "max-content" }}
							startIcon={<Add />}
							onClick={openAssigningDialog}
						>
							{textSnippets["assign_customer_button_manager_profile"] || "Assign Customer"}
						</CustomButton>
					</Box>
				</Box>

				{assignedCustomers?.length ? (
					<CustomTable
						headers={assignedCustomerHeaders}
						rows={assignedCustomers}
						detailsPagePath={allRoutes.VIEW_CUSTOMER}
					/>
				) : (
					<PlaceholderForEmptyTable
						message={`No customer is currently assigned to ${data?.name}. Please assign a customer.`}
					/>
				)}
			</Box>
			<AssignInstallerToCustomerDialog
				onSuccess={() => getDetails(true)}
				selectedCustomer={assigningCustomer}
				currentSelection={data}
				open={dialogForAssignInstaller}
				onClose={closeAssignInstallerDialog}
				type={roles.MANAGER}
			/>

			<AssignCustomerDialog
				open={dialogForNewCustomer}
				onClose={closeAssigningDialog}
				onSuccess={() => getDetails(true)}
				// currentSelection={data}
				installerDetails={data}
			/>

			<DeleteDialog
				open={deleteDialog}
				onClose={closeDeleteDialog}
				userType="Manager"
				user={data}
				onDelete={handleDelete}
			/>

			<AssignRepresentativeDialog
				open={assignRepresentativeDialog}
				onClose={closeAssignRepresentativeDialog}
				currentSelection={data}
				onSuccess={() => getDetails(true)}
				activeUser={assigningCustomer}
				type="Manager"
			/>
		</PageLayout>
	);
};

export default ManagerDetails;
