import CustomDialog from "../Common/CustomDialog";
import { TableBlock } from "./Dashboard";
import CustomTableOptions from "../Common/CustomTableOptions";
import { customerTableHeaders, downloadPDFFiles } from "../Representative/RepresentativeDetails";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../Routes/AllRoutes";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { DownloadOutlined } from "@mui/icons-material";
import StatusChip from "../Common/StatusChip";
import { steps } from "../../Utils/enums";

interface DialogProps {
	open: boolean;
	onClose?: () => void;
	onSuccess?: () => void;
	customers?: null | any[];
	selectedStep?: string | null;
}

const FunnelCustomerDialog = ({ open, onClose, customers, selectedStep }: DialogProps) => {
	const navigate = useNavigate();
	const isLeads = selectedStep === steps.LEAD;
	const isAcceptedProposal = selectedStep === steps.ACCEPTED_PROPOSAL;
	const isContractSigned = selectedStep === steps.CONTRACT_SIGNED;
	selectedStep = selectedStep === steps.PERMIT_APPLICATION ? "Permit and Interconnection" : selectedStep;

	const assignedCustomersTableHeaders = [
		...customerTableHeaders.filter((item) => item.key !== "currentStep"),
		...(!(isLeads || isAcceptedProposal || isContractSigned)
			? [
					{
						text: "Current Step",
						key: "step",
						customComponent: (props: { text: string }) => (
							<Typography variant="inherit" color="primary" fontWeight={600}>
								{props.text}
							</Typography>
						),
						sortable: true,
					},
					{
						text: "Status",
						key: "status",
						customComponent: (props: { text: string }) => <StatusChip status={props.text} />,
						sortable: true,
					},
			  ]
			: []),
		{
			text: "Report",
			key: "status",
			align: "center",
			notClickable: true,
			customComponent: (props: { fullObject: any }) => {
				const notReportsPresent = !props.fullObject?.summaryProposalPdfLink && !props.fullObject?.billAnalysisPdfLink;
				return (
					<Tooltip
						title={notReportsPresent ? "Reports have not been generated for this customer" : "Download Reports"}
						placement="top"
						arrow
					>
						<span>
							<IconButton
								color="primary"
								disabled={notReportsPresent}
								onClick={() =>
									downloadPDFFiles([props.fullObject?.summaryProposalPdfLink, props.fullObject?.billAnalysisPdfLink])
								}
							>
								<DownloadOutlined />
							</IconButton>
						</span>
					</Tooltip>
				);
			},
		},
		{
			text: "",
			key: "name",
			align: "right",
			notClickable: true,
			customComponent: (props: any) => (
				<CustomTableOptions
					menuOptions={[
						{
							text: "View Logs",
							onClick: () => {
								navigate(allRoutes.LOGS.replace(":id", props.sequentialId));
							},
						},

						{
							text: "Change Status",
							onClick: () => {
								navigate(allRoutes.UPDATE_STATUS.replace(":id", props.sequentialId));
							},
						},
					]}
				/>
			),
		},
	];

	return (
		<CustomDialog open={open} onClose={onClose} maxWidth={1000}>
			{customers && (
				<div>
					<TableBlock
						heading={`${selectedStep}s` as string}
						subHeading={`These are the customers in the ${selectedStep} funnel`}
						tableData={customers}
						tableHeaders={assignedCustomersTableHeaders}
						emptyStateMessage={`These are no customers in the ${selectedStep} funnel`}
						detailsPagePath={allRoutes.VIEW_CUSTOMER}
					/>
				</div>
			)}
		</CustomDialog>
	);
};

export default FunnelCustomerDialog;
