import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/installation";

// =====|  Dashboard Service  |=====

const DashboardService = {
	storeGoogleCalendarTokens: (data: { tokens: any }) =>
		http.post(`${apiEndpoint}/store-google-calendar-tokens`, data, { headers: getAuthHeader() }),
	getSlotsOfGoogle: () => http.get(`/installation/get-available-slots`, { headers: getAuthHeader() }),
	outlookCalendarCallbackApi: (data: { code: any }) =>
		http.post(`${apiEndpoint}/store-outlook-calendar-tokens`, data, { headers: getAuthHeader() }),
	getSlotsOfOutlook: () => http.get(`/installation/get-available-slots-outlook`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getSlotsOfGoogle = () => {
	return DashboardService.getSlotsOfGoogle();
};

export const getSlotsOfOutlook = () => {
	return DashboardService.getSlotsOfOutlook();
};

export const storeGoogleCalendarTokens = (tokens: any) => {
	const data = { tokens };
	return DashboardService.storeGoogleCalendarTokens(data);
};

export const outlookCalendarCallbackApi = (code: any) => {
	const data = { code };
	return DashboardService.outlookCalendarCallbackApi(data);
};
