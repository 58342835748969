import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/download-csv";

const DownloadCustomersCsvService = {
	downloadAssignedCustomersCsv: () =>
		http.get(`${apiEndpoint}/installer/assigned-customers`, {
			headers: getAuthHeader(),
			responseType: "blob",
		}),
	downloadUnAssignedCustomersCsv: () =>
		http.get(`${apiEndpoint}/installer/unassigned-customers`, {
			headers: getAuthHeader(),
			responseType: "blob",
		}),
	downloadCustomersProposalCsv: (userId: string) =>
		http.get(`${apiEndpoint}/summary-proposal/${userId}`, {
			headers: getAuthHeader(),
			responseType: "blob",
		}),
};

// =====|  APIs  |=====

export const downloadAssignedCustomersCsv = () => {
	return DownloadCustomersCsvService.downloadAssignedCustomersCsv();
};
export const downloadUnAssignedCustomersCsv = () => {
	return DownloadCustomersCsvService.downloadUnAssignedCustomersCsv();
};
export const downloadCustomersProposalCsv = (userId: string) => {
	return DownloadCustomersCsvService.downloadCustomersProposalCsv(userId);
};
