import http from "./httpService";
import { getAuthHeader, isUserLoggedIn } from "./userService";

const apiEndpoint = "/installer-colors";
const apiDefaultColorEndpoint = "/colors";

// =====|  Dashboard Service  |=====

const ColorsService = {
	getColors: () => http.get(`${apiEndpoint}/`, { headers: getAuthHeader() }),
	getDefaultColors: () => http.get(`${apiDefaultColorEndpoint}/`),
	addColors: (data: any) =>
		http.post(`${apiEndpoint}/add`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	updateColors: (data: any) =>
		http.put(`${apiEndpoint}/update`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
};

// =====|  APIs  |=====

export const getColors = () => {
	if (isUserLoggedIn()) {
		return ColorsService.getColors();
	} else {
		return ColorsService.getDefaultColors();
	}
};

export const updateColors = (data: any) => {
	return ColorsService.updateColors(data);
};
export const addColors = (data: any) => {
	return ColorsService.addColors(data);
};
