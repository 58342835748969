import { Avatar, Box, Typography } from "@mui/material";

const AvatarCell = ({ logo, name }: { logo?: string; name: string }) => {
	return (
		<Box display={"flex"} gap={"8px"} alignItems={"center"}>
			<Avatar src={logo} sx={{ height: "20px", width: "20px" }} />
			<Typography fontWeight={600} fontSize="inherit">
				{name}
			</Typography>
		</Box>
	);
};

export default AvatarCell;
