import axios from "axios";
import http from "./httpService";
import { AppDispatch } from "../Redux/store";
import { resetUserState, saveUserState } from "../Redux/Slices/userSlice";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/profile";

// =====|  Profile Service  |=====

const ProfileService = {
	getProfile: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
	getCurrentStep: () => http.get(`${apiEndpoint}/my-current-step`, { headers: getAuthHeader() }),
	updateProfile: (data: any) =>
		http.put(`${apiEndpoint}/edit-profile`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	verifyEmailOtp: (data: any) => http.post(`${apiEndpoint}/verifyOTP`, data, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const getProfile =
	() =>
	async (dispatch: AppDispatch): Promise<any | void> => {
		try {
			const { data }: any = await ProfileService.getProfile();
			console.log("Profile: ", data);

			const userData = getUserObjectForRedux(data?.UserInformation);
			dispatch(resetUserState());
			dispatch(saveUserState(userData));

			return userData;
		} catch (error) {
			console.error("getProfile (API): ", error);
			throw error;
		}
	};

export const updateProfile =
	(formData: any) =>
	async (dispatch: AppDispatch): Promise<any | void> => {
		try {
			const { data } = await ProfileService.updateProfile(formData);
			console.log("Updated Profile: ", data);

			const userData = getUserObjectForRedux(data?.User?.update_profile);
			dispatch(saveUserState(userData));

			return userData;
		} catch (error) {
			console.error("updateProfile (API): ", error);
			throw error;
		}
	};

export const getCurrentStep = () => {
	return ProfileService.getCurrentStep();
};

export const verifyEmailOtp = (data: any) => {
	return ProfileService.verifyEmailOtp(data);
};

// TODO in future: move this google api and all other google apis in a separate googleApiService file
export const getGoogleProfile = async (accessToken: string) => {
	const { data } = await axios.get("https://www.googleapis.com/oauth2/v2/userinfo", {
		headers: { Authorization: `Bearer ${accessToken}` },
	});
	return data;
};

const getUserObjectForRedux = (user: any) => ({
	id: user?._id,
	bill: user?.electricity_usage?.toString(),
	address: user?.address,
	name: user?.name,
	email: user?.email,
	role: user?.role,
	companyId: user?.companyId,
	officeManagerId: user?.officeManagerId,
	password: user?.password,
	picture: user?.ImageUrl || "",
	phone: user?.phone_no,
	refreshToken: user?.refreshToken,
	outlookRefreshToken: user?.outlookRefreshToken,

	company : user?.company ,
	...(user?.electricity_bill
		? {
				billData: {
					file: user?.electricity_bill,
					delivery: user?.deliveryCharges,
					supply: user?.supplyCharges,
					provider: user?.providerName,
				},
		  }
		: {}),
});
