import { Box, keyframes, styled } from "@mui/material";
import colors from "../../Utils/colors";
import { borderRadius, navbarHeight } from "../../Utils/spacings";

export const LoginContainer = styled(Box)(({ theme }) => ({
	display: "grid",
	gridTemplateColumns: "1fr 1.5fr",
	gap: 32,
	padding: 32,
	maxWidth: "100vw",

	[theme.breakpoints.down("md")]: {
		gridTemplateColumns: "1fr",
	},
}));

export const LoginLeftBlock = styled(Box)(({ theme }) => ({
	padding: "38px 83px 0px 83px",
	width: "100%",
	minWidth: 540,
	minHeight: `calc(100vh - 32px - 32px - ${navbarHeight}px)`,
	display: "flex",
	flexDirection: "column",

	[theme.breakpoints.down("md")]: {
		padding: 0,
		minWidth: "auto",
	},
}));

export const LoginRightBlock = styled(Box)(({ theme }) => ({
	padding: 38,
	width: "100%",
	height: `calc(100vh - 32px - 32px - ${navbarHeight}px)`,
	position: "sticky",
	top: `${32 + navbarHeight}px`,

	"& .purple-box": {
		backgroundColor: colors.primaryExtraLight,
		padding: 22,
		width: "100%",
		height: "100%",
		borderRadius: borderRadius.xl,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",

		"& .login-image": {
			width: "65%",
			// border: `1px solid ${colors.border}`,
			// borderRadius: borderRadius.sm,
			zIndex: 3,
			animation: `0.4s ${fadeUp} 0s ease both`,

			"&.img-2": {
				zIndex: 2,
				position: "absolute",
				animation: `0.4s ${fadeUpRotateLeft} 0s ease both`,
			},
			"&.img-3": {
				zIndex: 1,
				position: "absolute",
				animation: `0.4s ${fadeUpRotateRight} 0s ease both`,
			},
		},
	},

	[theme.breakpoints.down("md")]: {
		padding: 0,
		height: "auto",
		display: "none",
	},
}));

export const IconSquareBox = styled(Box)(() => ({
	height: 53,
	width: 53,
	border: `1px solid ${colors.border}`,
	borderRadius: borderRadius.sm,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	marginInline: "auto",
	marginTop: 10,
}));

// ==========|  Animations  |==========

const fadeUp = keyframes`
  0% {
    transform: translateY(150px);
		opacity: 0;
  }
  60% {
    transform: translateY(-15px);
		opacity: 1;
  }
  100% {
    transform: translateY(0);
		opacity: 1;
  }
`;

const fadeUpRotateLeft = keyframes`
  0% {
    transform: rotate(0deg) translateX(0);
		opacity: 0;
  }
  60% {
    transform: rotate(-6deg) translateX(-16%);
		opacity: 1;
  }
  100% {
    transform: rotate(-5deg) translateX(-15%);
		opacity: 1;
  }
`;

const fadeUpRotateRight = keyframes`
  0% {
    transform: rotate(0deg) translateX(0);
		opacity: 0;
  }
  60% {
    transform: rotate(6deg) translateX(16%);
		opacity: 1;
  }
  100% {
    transform: rotate(5deg) translateX(15%);
		opacity: 1;
  }
`;
