import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/admin";

// =====|  Dashboard Service  |=====

const DashboardService = {
	getDashboardData: () => http.get(`${apiEndpoint}/all-users`, { headers: getAuthHeader() }),
	changeTechnician: (customerId: string, data: any) =>
		http.put(`${apiEndpoint}/update-assigned-technician/${customerId}`, data, { headers: getAuthHeader() }),
	changeInstaller: (customerId: string, data: any) =>
		http.put(`${apiEndpoint}/update-assigned-installer/${customerId}`, data, { headers: getAuthHeader() }),
		getInstallerCompanyDetails: (companyId: string) =>
		http.get(`${apiEndpoint}/get-installer-company-details/${companyId}`, { headers: getAuthHeader() }),
	// customers apis
	getCustomersWithCurrentSteps: () => http.get(`${apiEndpoint}/customers-current-steps`, { headers: getAuthHeader() }),
	getCustomerDetails: (customerId: string) =>
		http.get(`${apiEndpoint}/customer-current-step/${customerId}`, { headers: getAuthHeader() }),
		updateAccountDetails: (userId: string, data: any) =>
		http.put(`${apiEndpoint}/add-password/${userId}`, data , {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}
		),
	getAssignedTechnician: (customerId: string) =>
		http.get(`${apiEndpoint}/assigned-technician/${customerId}`, { headers: getAuthHeader() }),
	getAssignedInstaller: (customerId: string) =>
		http.get(`${apiEndpoint}/assigned-installer/${customerId}`, { headers: getAuthHeader() }),
	updateCustomer: (customerId: string, data: any) =>
		http.put(`${apiEndpoint}/update-customer/${customerId}`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	deleteCustomer: (customerId: string) =>
		http.delete(`${apiEndpoint}/delete-user/${customerId}`, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	addNewCustomer: (data: any) =>
		http.post(`${apiEndpoint}/add-customer`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),

	// admins apis
	getAllAdmins: () => http.get(`${apiEndpoint}/admins`, { headers: getAuthHeader() }),
	addNewAdmin: (data: any) =>
		http.post(`${apiEndpoint}/add-admin`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),updateInstallerCompany: (companyId: string, data: any) =>
		http.put(`${apiEndpoint}/update-installer-company/${companyId}`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),

	// installers apis
	getAllInstallers: () => http.get(`${apiEndpoint}/installers`, { headers: getAuthHeader() }),
	addNewInstaller: (data: any) =>
		http.post(`${apiEndpoint}/add-installer`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	getInstallerCustomers: (installerId: string) =>
		http.get(`${apiEndpoint}/installer-customers/${installerId}`, { headers: getAuthHeader() }),

	// technicians apis
	getAllTechnicians: () => http.get(`${apiEndpoint}/all-technicians`, { headers: getAuthHeader() }),
	addNewTechnician: (data: any) =>
		http.post(`${apiEndpoint}/add-technician`, data, {
			headers: { "Content-Type": "multipart/form-data", ...getAuthHeader() },
		}),
	getTechnicianCustomers: (installerId: string) =>
		http.get(`${apiEndpoint}/customers-of-technician/${installerId}`, { headers: getAuthHeader() }),
	
};

// =====|  APIs  |=====

export const getDashboardData = () => {
	return DashboardService.getDashboardData();
};

export const changeTechnician = (customerId: string, data: any) => {
	return DashboardService.changeTechnician(customerId, data);
};

export const changeInstaller = (customerId: string, data: any) => {
	return DashboardService.changeInstaller(customerId, data);
};

export const getCustomersWithCurrentSteps = () => {
	return DashboardService.getCustomersWithCurrentSteps();
};

export const getCustomerDetails = (customerId: string) => {
	return DashboardService.getCustomerDetails(customerId);
};

export const deleteCustomer = (customerId: string) => {
	return DashboardService.deleteCustomer(customerId);
};

export const getAssignedTechnician = (customerId: string) => {
	return DashboardService.getAssignedTechnician(customerId);
};

export const getAssignedInstaller = (customerId: string) => {
	return DashboardService.getAssignedInstaller(customerId);
};

export const updateCustomer = (customerId: string, data: any) => {
	return DashboardService.updateCustomer(customerId, data);
};

export const addNewCustomer = (data: any) => {
	return DashboardService.addNewCustomer(data);
};

export const getAllAdmins = () => {
	return DashboardService.getAllAdmins();
};

export const addNewAdmin = (data: any) => {
	return DashboardService.addNewAdmin(data);
};

export const getAllInstallers = () => {
	return DashboardService.getAllInstallers();
};

export const addNewInstaller = (data: any) => {
	return DashboardService.addNewInstaller(data);
};

export const getInstallerCustomers = (installerId: string) => {
	return DashboardService.getInstallerCustomers(installerId);
};

export const getAllTechnicians = () => {
	return DashboardService.getAllTechnicians();
};

export const addNewTechnician = (data: any) => {
	return DashboardService.addNewTechnician(data);
};

export const getTechnicianCustomers = (technicianId: string) => {
	return DashboardService.getTechnicianCustomers(technicianId);
};




export const getInstallerCompanyDetails = (id: string) => {
	return DashboardService.getInstallerCompanyDetails(id);
};

export const updateInstallerCompany = (id: string, data: any) => {
	return DashboardService.updateInstallerCompany(id, data);
};



export const updateAccountDetails = ( userId : string , data:any) => {
	return DashboardService.updateAccountDetails( userId ,data ,);
};